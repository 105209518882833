import { CommonModule, DatePipe } from '@angular/common';
import {
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { v4 as uuidv4 } from 'uuid';
import * as pdfjsLib from 'pdfjs-dist';
import { firstValueFrom } from 'rxjs';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { FormioRendererComponent } from '../../data-interaction/formio-renderer/formio-renderer.component';
import { MediaToolbarComponent } from '../../media-toolbar/media-toolbar.component';
import { PdfViewerComponent } from '../../utility/pdf-viewer/pdf-viewer.component';
import {
    fadeInFromLeft,
    fadeInFromRight,
    fadeInFromTop,
} from '../../../shared/animations';
import { AddPatientFileComponent } from '../../../modals/add-patient-file/add-patient-file.component';
import { MatDialog } from '@angular/material/dialog';
import {
    Area,
    Findings,
    FindingsByRecords,
    Record,
    SubArea,
} from '../../../models/patient-records.model';
import { LabResult } from '../../../models/view-content.models/view-content-clinic-domain.model';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LabTableViewerComponent } from '../lab-table-viewer/lab-table-viewer.component';
import { LabResultViewerComponent } from '../lab-result-viewer/lab-result-viewer.component';
import { DicomViewerComponent } from '../../dicom-viewer/dicom-viewer.component';
import { TextEditorBridgeComponent } from '../../utility/text-editor-bridge/text-editor-bridge.component';
import { ActivatedRoute } from '@angular/router';

/**
 * Component for displaying patient files.
 */
@Component({
    selector: 'patient-files',
    templateUrl: './patient-files.component.html',
    styleUrls: ['./patient-files.component.scss'],
    standalone: true,
    animations: [fadeInFromRight, fadeInFromLeft, fadeInFromTop],
    imports: [
        CommonModule,
        DatePipe,
        DicomViewerComponent,
        FormioRendererComponent,
        FormsModule,
        LabResultViewerComponent,
        LabTableViewerComponent,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MediaToolbarComponent,
        PdfViewerComponent,
    ],
})
export class PatientFilesComponent implements OnChanges, OnInit {
    @Input() isFullscreenForImageEnabled!: { data: any; fullScreen: boolean };
    @Input() isPaintingToolOpened!: {
        data: any;
        isPaintingToolDialogOpened: boolean;
    };
    @Input() isMetaDataViewOpened = false;
    @Input() findings: Findings[] = [];
    @Input() areas: Area[] = [];
    @Input() subAreas: SubArea[] = [];
    @Input() records: Record[] = [];
    @Input() selectedRecord!: Record;
    caseId?: string;

    @Output() clickOnFullScreen = new EventEmitter<{
        data: any;
        fullScreen: boolean;
    }>();
    @Output() isPaintingToolOpenedChanged = new EventEmitter<{
        data: any;
        isPaintingToolDialogOpened: boolean;
    }>();
    @Output() isMetaDataViewChanged = new EventEmitter<boolean>();

    public selectedFinding: Findings = {} as Findings;
    public findingsByRecords: FindingsByRecords<Findings> =
        {} as FindingsByRecords<Findings>;
    public currentArea: string = 'All';
    public isDropdownOpen = false;
    public isLabTableShowLimits = true;
    protected readonly Object = Object;

    public get allLabResults(): LabResult[] {
        return this.findings
            .filter((e) => e.documentType === 'lab-report' && e.labResult)
            .map((e) => e.labResult!);
    }

    private destroyRef = inject(DestroyRef);

    public constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute
    ) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.mjs';
    }

    ngOnInit(): void {
        // @todo: Remove this once the caseId is passed from the parent component or from view-content
        this.caseId = this.route.snapshot.paramMap.get('id') ?? undefined;
    }

    /**
     * Lifecycle hook that is called when any data-bound property of a directive changes.
     * @param changes - SimpleChanges object that contains current and previous property values.
     */
    public async ngOnChanges(changes: SimpleChanges) {
        if (
            changes['findings'] &&
            changes['findings'].currentValue.length > 0
        ) {
            this.findings.sort((e1, e2) =>
                e2.dateTimeOfRecord.localeCompare(e1.dateTimeOfRecord)
            );
            await this.buildFindingList();
            this.selectInitialPatientRecord();
            await this.handlePatientRecordViewer(this.selectedFinding);
            this.buildLabTable();
        }
    }

    /**
     * Toggles the state of the dropdown menu.
     */
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    public onClickOnLabViewerFullScreen($event: LabResult[]): void {
        const data = {
            labResults: $event,
            documentType: 'labResults',
        };
        this.clickOnFullScreen.emit({ data, fullScreen: true });
    }

    /**
     * Emits an event when the fullscreen mode for the image is changed.
     * @param ev - The new fullscreen state.
     */
    public onClickOnFullScreen(ev: { data: any; fullScreen: boolean }) {
        if (this.selectedFinding.documentType === 'image') {
            ev.data = {
                documentPath: this.selectedFinding.documentPath,
                documentType: this.selectedFinding.documentType,
            };
        } else if (this.selectedFinding.documentType === 'pdf') {
            ev.data = {
                pdfBlob: this.selectedFinding.pdfBlob,
                documentType: this.selectedFinding.documentType,
            };
        }
        this.clickOnFullScreen.emit(ev);
    }

    public onClickOnLaborViewer(): void {
        const s = this.findings.find((e) => e.documentType === 'lab-table');
        if (s) this.selectedFinding = s;
    }

    /**
     * Emits an event when the painting tool state is changed.
     * @param ev - The new painting tool state.
     */
    public onPaintingToolToggleChanged(ev: {
        data: any;
        isPaintingToolDialogOpened: boolean;
    }) {
        if (this.selectedFinding.documentType === 'image') {
            ev.data = {
                document_id: this.selectedFinding.id,
                documentType: this.selectedFinding.documentType,
                image: this.selectedFinding.documentPath,
            };
        } else if (this.selectedFinding.documentType === 'pdf') {
            ev.data = {
                document_id: this.selectedFinding.id,
                documentType: this.selectedFinding.documentType,
                pdfBlob: this.selectedFinding.pdfBlob,
            };
        }
        this.isPaintingToolOpenedChanged.emit(ev);
    }

    /**
     * Emits an event when the metadata view state is changed.
     * @param ev - The new metadata view state.
     */
    public onMetaDataViewToggleChanged(ev: boolean) {
        this.isMetaDataViewChanged.emit(ev);
    }

    /**
     * Changes the current area and updates the selected patient record.
     * @param areaName - The name of the new area.
     */
    public async changeArea(areaName: string) {
        console.log(areaName);
        this.currentArea = areaName;
        this.selectInitialPatientRecord();
        await this.handlePatientRecordViewer(this.selectedFinding);
    }

    /**
     * Selects a patient record by click event.
     * @param event - The event object containing the selected finding.
     */
    public async onClickOnFileListItem(event: any): Promise<void> {
        this.selectedFinding = event;
        await this.handlePatientRecordViewer(this.selectedFinding);
    }

    /**
     * Opens a dialog to add a new patient record.
     */
    public async addNewPatientRecord(): Promise<void> {
        const dialogRef = this.dialog.open(AddPatientFileComponent, {
            restoreFocus: false,
            data: this.areas,
        });

        const res = await firstValueFrom(dialogRef.afterClosed());
        if (res.role === 'save') {
            const newPatientRecord: Findings = {
                ...res.newPatientRecord,
                id: uuidv4(),
            };

            this.findings.push(newPatientRecord);
            await this.buildFindingList();
        }
    }

    /**
     * Selects the initial patient record based on the current area.
     */
    public selectInitialPatientRecord() {
        const selectedRecordName = this.selectedRecord.name;

        if (this.currentArea !== 'All') {
            const areas = this.findingsByRecords[selectedRecordName];
            if (areas) {
                const subAreas = areas[this.currentArea];
                if (subAreas) {
                    const firstSubArea = Object.keys(subAreas)[0];
                    if (firstSubArea) {
                        this.selectedFinding = subAreas[firstSubArea][0];
                    }
                }
            }
        } else {
            const allRecords = this.findingsByRecords[selectedRecordName];
            if (allRecords) {
                const firstArea = Object.keys(allRecords)[0];
                if (firstArea) {
                    const subAreas = allRecords[firstArea];
                    const firstSubArea = Object.keys(subAreas)[0];
                    if (firstSubArea) {
                        this.selectedFinding = subAreas[firstSubArea][0];
                    }
                }
            }
        }
    }

    public getFilteredAreasBySelectedRecord(): Area[] {
        return this.areas.filter(
            (area) => area.recordId === this.selectedRecord.id
        );
    }

    /**
     * Builds a new list or update of patient records.
     */
    private async buildFindingList() {
        const result: FindingsByRecords<Findings> = {};

        const areaMap = new Map(this.areas.map((area) => [area.id, area]));
        const subAreaMap = new Map(
            this.subAreas.map((subArea) => [subArea.id, subArea])
        );
        const findingsByRecord = this.groupFindingsByRecordId();

        for (const record of this.records) {
            result[record.name] = this.processRecord(
                record,
                findingsByRecord,
                areaMap,
                subAreaMap
            );
        }

        this.sortAreasAndSubAreas(result);

        this.findingsByRecords = result;
    }

    private groupFindingsByRecordId(): Map<string, Findings[]> {
        return this.findings.reduce((map, finding) => {
            const recordId = finding.area.recordId;
            if (!map.has(recordId)) {
                map.set(recordId, []);
            }
            map.get(recordId)!.push(finding);
            return map;
        }, new Map<string, Findings[]>());
    }

    private processRecord(
        record: Record,
        findingsByRecord: Map<string, Findings[]>,
        areaMap: Map<string, Area>,
        subAreaMap: Map<string, SubArea>
    ): { [areaName: string]: { [subAreaName: string]: Findings[] } } {
        const recordResult: {
            [areaName: string]: { [subAreaName: string]: Findings[] };
        } = {};
        const recordFindings = findingsByRecord.get(record.id) || [];
        const areasByRecord = this.areas
            .filter((area) => area.recordId === record.id)
            .sort((a, b) => a.order - b.order);

        for (const area of areasByRecord) {
            recordResult[area.name] = this.processArea(
                area,
                recordFindings,
                subAreaMap
            );
        }

        this.handleSonstigesCases(
            recordResult,
            recordFindings,
            areaMap,
            subAreaMap
        );

        return recordResult;
    }

    private processArea(
        area: Area,
        recordFindings: Findings[],
        subAreaMap: Map<string, SubArea>
    ): { [subAreaName: string]: Findings[] } {
        const areaResult: { [subAreaName: string]: Findings[] } = {};
        const subAreasByArea = this.subAreas
            .filter((subArea) => subArea.areaId === area.id)
            .sort((a, b) => a.order - b.order);

        for (const subArea of subAreasByArea) {
            const subAreaFindings = recordFindings
                .filter(
                    (finding) =>
                        finding.subArea.id === subArea.id &&
                        finding.area.id === area.id
                )
                .sort((a, b) => a.order - b.order);

            areaResult[subArea.name] = subAreaFindings;
        }

        return areaResult;
    }

    private handleSonstigesCases(
        recordResult: { [area: string]: { [subArea: string]: Findings[] } },
        recordFindings: Findings[],
        areaMap: Map<string, Area>,
        subAreaMap: Map<string, SubArea>
    ) {
        for (const finding of recordFindings) {
            const area = areaMap.get(finding.area.id);
            const subArea = subAreaMap.get(finding.subArea.id);

            if (!area) {
                this.addToSonstiges(
                    recordResult,
                    'Sonstiges',
                    subArea?.name || 'Sonstiges',
                    finding
                );
            } else if (!subArea) {
                this.addToSonstiges(
                    recordResult,
                    area.name,
                    'Sonstiges',
                    finding
                );
            }
        }

        if (recordResult['Sonstiges']) {
            Object.values(recordResult['Sonstiges']).forEach((findings) =>
                findings.sort((a, b) => a.order - b.order)
            );
        }
    }

    private addToSonstiges(
        recordResult: { [area: string]: { [subArea: string]: Findings[] } },
        areaName: string,
        subAreaName: string,
        finding: Findings
    ) {
        if (!recordResult[areaName]) recordResult[areaName] = {};
        if (!recordResult[areaName][subAreaName])
            recordResult[areaName][subAreaName] = [];

        recordResult[areaName][subAreaName].push({
            ...finding,
            area: { ...finding.area, order: 9999 },
            subArea: { ...finding.subArea, order: 9999 },
        });
    }

    private sortAreasAndSubAreas(result: FindingsByRecords<Findings>) {
        for (const recordName in result) {
            const sortedAreas = Object.keys(result[recordName]).sort(
                this.compareAreas.bind(this)
            );

            result[recordName] = sortedAreas.reduce((acc, areaName) => {
                const sortedSubAreas = Object.keys(
                    result[recordName][areaName]
                ).sort(this.compareSubAreas.bind(this));

                acc[areaName] = sortedSubAreas.reduce((subAcc, subAreaName) => {
                    subAcc[subAreaName] =
                        result[recordName][areaName][subAreaName];
                    return subAcc;
                }, {} as { [key: string]: Findings[] });

                return acc;
            }, {} as { [key: string]: { [key: string]: Findings[] } });
        }
    }

    private compareAreas(a: string, b: string): number {
        if (a === 'Sonstiges') return 1;
        if (b === 'Sonstiges') return -1;
        const areaA = this.areas.find((area) => area.name === a);
        const areaB = this.areas.find((area) => area.name === b);
        return (areaA?.order ?? Infinity) - (areaB?.order ?? Infinity);
    }

    private compareSubAreas(a: string, b: string): number {
        if (a === 'Sonstiges') return 1;
        if (b === 'Sonstiges') return -1;
        const subAreaA = this.subAreas.find((subArea) => subArea.name === a);
        const subAreaB = this.subAreas.find((subArea) => subArea.name === b);
        return (subAreaA?.order ?? Infinity) - (subAreaB?.order ?? Infinity);
    }

    /**
     * Fetches a PDF as a Blob.
     * @param pdfUrl - The URL of the PDF to fetch.
     * @returns A promise that resolves to a Blob.
     */
    private async fetchPdfAsBlob(pdfUrl: string): Promise<Blob> {
        try {
            const response = await fetch(pdfUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch PDF: ${response.statusText}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            return new Blob([arrayBuffer], { type: 'application/pdf' });
        } catch (error) {
            console.error('Error fetching or converting PDF:', error);
            throw error;
        }
    }

    /**
     * Handles the patient record viewer by fetching the necessary document.
     * @param finding - The finding to handle.
     */
    private async handlePatientRecordViewer(finding: Findings) {
        if (finding?.documentPath) {
            finding.pdfBlob = await this.fetchPdfAsBlob(finding.documentPath);
        }
    }

    private buildLabTable(): void {
        const labTable = this.findings
            .filter((e) => e.documentType === 'lab-report' && e.labResult)
            .map((e) => e.labResult!);
        this.findings.push({
            id: 'lab-table-id',
            createdBy: '',
            title: 'Labortabelle',
            order: 0,
            area: {
                id: 'lab-table-area-id',
                recordId: '',
                name: 'Lab Table Area',
                version: '0',
                validFrom: '',
                validUntil: '',
                order: 0,
                subAreas: [],
            },
            subArea: {
                id: 'lab-table-subarea-id',
                areaId: 'lab-table-area-id',
                name: 'Lab Table Subarea',
                version: '0',
                validFrom: '',
                validUntil: '',
                order: 0,
            },
            examinationDateTime: '',
            dateTimeOfRecord: '',
            validFrom: '',
            validUntil: '',
            documentType: 'lab-table',
            labTable,
        });
    }

    public openTextEditor() {
        // Opens a dialog for adding a new patient file and prevents it from auto-refocusing
        const dialogRef = this.dialog.open(TextEditorBridgeComponent, {
            restoreFocus: false,
            height: '856px',
            maxHeight: '100vh',
            maxWidth: '100%',
            width: '100%',
            data: {
                caseId: this.caseId,
            },
        });
    }
}
