import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
    convertMultiframeImageIds,
    prefetchMetadataInformation,
} from '../../shared/convertMultiframeImageIds';
import { ImageService } from './helper/image.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DocsService } from '../../services/docs.service';
import { AccessFacadeService } from '../../services/facades/access-facade.service';
import { switchMap } from 'rxjs';

@Component({
    selector: 'app-dicom-viewer',
    templateUrl: './dicom-viewer.component.html',
    imports: [MatButtonModule, MatIconModule, MatToolbarModule],
    standalone: true,
    styleUrls: ['./dicom-viewer.component.scss'],
})
export class DicomViewerComponent implements OnInit, OnDestroy {
    @Input({ required: false }) blob!: Blob;

    private readonly accessFacade = inject(AccessFacadeService);
    private imageService = inject(ImageService);
    private documentService = inject(DocsService);
    private http = inject(HttpClient);

    ngOnInit(): void {
        this.accessFacade.userTokens$.pipe(
            switchMap((userTokens) => {
                // Patienten-ID: 200, 173
                // Dokument-ID: 2313, 2305

                console.log("Usertoken", userTokens);
                console.log("userTokens.token?.patient", userTokens.token?.patient);

                return this.documentService
                    .getDocuments(
                        userTokens.token?.access_token || '',
                        200,
                        2313
                    )
                    .then((response) => {
                        // console.log('🚀 ~ DICOM ~ response:', response);
                    });

                // return this.docsService.createDocument(
                //     userTokens.token?.access_token || '',
                //     {
                //         documenttext: base64,
                //         case: this.data.caseId,
                //     }
                // );
            })
        ).subscribe((data) => {
            // console.log('🚀 ~ DICOM ~ data:', data);
        });

        // TEST on route /test
        this.http
            .get('assets/dicom/0002.DCM', { responseType: 'blob' })
            .subscribe((blob) => {
                this.proccessFile(blob);
            });
        // this.proccessFile(this.blob);
    }

    async proccessFile(file: any) {
        const imageId = this.imageService.getImageIdFromFile(file);

        await prefetchMetadataInformation([imageId]);
        const stack = convertMultiframeImageIds([imageId]);

        const dataSet = await this.imageService.loadImage(imageId);
        const patientName = dataSet.data.string('x00100010');
        // console.log('🚀 ~ AppComponent ~  patientName:', patientName);

        await this.imageService.loadImageOnViewport(imageId, stack);
    }

    ngOnDestroy(): void {
        this.imageService.clean();
    }

    public onZoomIn() {
        this.imageService.zoomIn();
    }

    public onReset() {
        this.imageService.resetZoom();
    }

    public onNextImage() {
        this.imageService.nextImage();
    }

    public onPreviousImage() {
        this.imageService.previousImage();
    }
}
