<div
    class="w-full h-full bg-[var(--color-background-primary-color)] p-2 flex flex-col"
>
    <div [@fadeInFromTop] class="flex space-x-4 mb-2">
        <div class="flex items-center justify-between w-full">
            <!-- Category chips -->
            <div class="flex gap-2 items-center">
                <mat-chip-option
                    (click)="changeArea('All')"
                    [selected]="currentArea === 'All'"
                    ><span class="font-light">Alle</span>
                </mat-chip-option>

                <mat-chip-option
                    (click)="changeArea(area.name)"
                    *ngFor="let area of getFilteredAreasBySelectedRecord()"
                    [selected]="currentArea === area.name"
                >
                    <span class="font-light">{{ area.name }}</span>
                </mat-chip-option>

                <mat-chip-option
                    (click)="changeArea('Sonstiges')"
                    *ngIf="
                        Object.keys(
                            findingsByRecords[this.selectedRecord.name]
                        ).includes('Sonstiges')
                    "
                    [selected]="currentArea === 'Sonstiges'"
                >
                    <span class="font-light">Sonstiges</span>
                </mat-chip-option>

                <mat-chip [disabled]="true" [matMenuTriggerFor]="menu">
                    <mat-icon class="p-0 m-0 pt-[2px]">add</mat-icon>
                </mat-chip>
                <mat-menu #menu="matMenu">
                    <button (click)="addNewPatientRecord()" mat-menu-item>
                        <mat-icon>description</mat-icon>
                        <span>Dokument erstellen</span>
                    </button>
                    <button
                        (click)="addNewPatientRecord()"
                        disabled
                        mat-menu-item
                    >
                        <mat-icon>publish</mat-icon>
                        <span>Exportieren</span>
                    </button>
                </mat-menu>
            </div>

            <div class="flex items-center">
                <button
                    mat-icon-button
                    (click)="openTextEditor()"
                >
                    <mat-icon>note_add</mat-icon>
                </button>

                <!-- Ambulant / Stationary selector -->
                <div
                    *ngIf="selectedRecord"
                    class="relative inline-block text-left"
                    style="z-index: 999999"
                >
                    <div>
                        <button
                            (click)="toggleDropdown()"
                            aria-expanded="true"
                            aria-haspopup="true"
                            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center"
                            id="menu-button"
                            type="button"
                        >
                            {{ selectedRecord.name }}
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                    </div>

                    <div
                        *ngIf="isDropdownOpen"
                        aria-labelledby="menu-button"
                        aria-orientation="vertical"
                        class="absolute right-0 z-10 mt-2 min-w-44 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        tabindex="-1"
                    >
                        <div class="py-1" role="none">
                            <a
                                (click)="
                                    selectedRecord = r;
                                    currentArea = 'All';
                                    selectInitialPatientRecord();
                                    isDropdownOpen = false
                                "
                                *ngFor="let r of records"
                                class="px-4 py-2 text-sm text-gray-700 flex justify-between items-center text-nowrap hover:bg-gray-100 cursor-pointer"
                                role="menuitem"
                                tabindex="-1"
                            >
                                <p>{{ r.name }}</p>
                                <mat-icon *ngIf="r.id === selectedRecord.id"
                                    >check</mat-icon
                                >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Files list -->
    <div class="flex flex-grow flex-col gap-2 sm:flex-row h-full">
        <div
            [@fadeInFromLeft]
            class="w-full sm:w-1/3 overflow-y-auto min-h-[30%] max-h-[calc(100%-1.5rem)] h-full"
        >
            <!-- Lab viewer button (to see lab table) -->
            <button
                *ngIf="currentArea === 'Labor'"
                mat-flat-button
                (click)="onClickOnLaborViewer()"
            >
                Laborviewer
            </button>

            <ng-container *ngIf="findingsByRecords[selectedRecord.name]">
                <ng-container
                    *ngFor="
                        let areaName of Object.keys(
                            findingsByRecords[selectedRecord.name]
                        )
                    "
                >
                    <ng-container
                        *ngIf="
                            currentArea === 'All' || currentArea === areaName
                        "
                    >
                        <ng-container
                            *ngFor="
                                let subareaName of Object.keys(
                                    findingsByRecords[selectedRecord.name][
                                        areaName
                                    ]
                                )
                            "
                        >
                            <p
                                *ngIf="
                                    (
                                        findingsByRecords[selectedRecord.name][
                                            areaName
                                        ][subareaName] ?? []
                                    ).length > 0
                                "
                                class="text-gray-700"
                            >
                                {{ subareaName }}
                            </p>
                            <div
                                (click)="onClickOnFileListItem(record)"
                                *ngFor="
                                    let record of findingsByRecords[
                                        selectedRecord.name
                                    ][areaName][subareaName]
                                "
                                [ngClass]="
                                    selectedFinding?.id === record.id
                                        ? 'border-[var(--primary-color)]'
                                        : 'border-transparent'
                                "
                                class="mb-4 p-2 bg-white shadow-md border-2 cursor-pointer"
                            >
                                <p class="text-gray-500 text-lg font-light">
                                    {{ record.title }}
                                </p>
                                <p class="text-gray-700 font-light">
                                    B:
                                    {{
                                        record.examinationDateTime
                                            | date : "dd.MM.yyyy HH:mm"
                                    }}
                                    E:
                                    {{
                                        record.dateTimeOfRecord
                                            | date : "dd.MM.yyyy HH:mm"
                                    }}
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <!-- File viewer -->
        <div
            [@fadeInFromRight]
            [ngClass]="{ 'sm:!w-1/3': isMetaDataViewOpened }"
            class="w-full sm:w-2/3 flex flex-col overflow-auto max-h-[calc(100%-1.5rem)] h-full"
        >
            <ng-container [ngSwitch]="selectedFinding?.documentType">
                <!-- Form renderer -->
                <div *ngSwitchCase="'form'" class="flex-grow">
                    <app-formio-renderer
                        [data]="selectedFinding?.formioRendererData ?? []"
                        [form]="selectedFinding?.formioRendererForm"
                        [i18n]="selectedFinding?.formioRendererI18n"
                        [maxRows]="10"
                        [readonly]="true"
                    >
                    </app-formio-renderer>
                </div>

                <!-- Image viewer -->
                <div
                    *ngSwitchCase="'image'"
                    class="flex-grow flex flex-col h-full"
                >
                    <media-toolbar
                        (isFullscreenForImageEnabledChanged)="
                            onClickOnFullScreen($event)
                        "
                        (isMetaDataViewOpenedChanged)="
                            onMetaDataViewToggleChanged($event)
                        "
                        (isPaintingToolOpenedChanged)="
                            onPaintingToolToggleChanged($event)
                        "
                        [isFullscreenForImageEnabled]="
                            isFullscreenForImageEnabled
                        "
                        [isMetadataViewOpened]="isMetaDataViewOpened"
                        [isPaintingToolOpened]="isPaintingToolOpened"
                    >
                    </media-toolbar>
                    <div class="flex-grow overflow-hidden flex">
                        <div class="flex-grow relative">
                            <img
                                [src]="selectedFinding?.documentPath"
                                alt="Image"
                                class="absolute inset-0 w-full h-full object-contain pb-2"
                            />
                        </div>
                    </div>
                </div>

                <!-- PDF viewer -->
                <div *ngSwitchCase="'pdf'" class="flex-grow flex flex-col">
                    <media-toolbar
                        (isFullscreenForImageEnabledChanged)="
                            onClickOnFullScreen($event)
                        "
                        (isMetaDataViewOpenedChanged)="
                            onMetaDataViewToggleChanged($event)
                        "
                        (isPaintingToolOpenedChanged)="
                            onPaintingToolToggleChanged($event)
                        "
                        [isFullscreenForImageEnabled]="
                            isFullscreenForImageEnabled
                        "
                        [isMetadataViewOpened]="isMetaDataViewOpened"
                        [isPaintingToolOpened]="isPaintingToolOpened"
                    >
                    </media-toolbar>
                    <div class="flex-grow overflow-hidden pdfViewerContainer">
                        <app-pdf-viewer
                            *ngIf="selectedFinding.pdfBlob"
                            [src]="selectedFinding.pdfBlob"
                            class="w-full h-full"
                        ></app-pdf-viewer>
                    </div>
                </div>

                <!-- DICOM viewer-->
                <div *ngSwitchCase="'dicom'" class="flex-grow">
                    <app-dicom-viewer />
                </div>

                <!-- Lab result viewer-->
                <div *ngSwitchCase="'lab-report'" class="flex-grow">
                    <app-lab-result-viewer
                        [labResult]="selectedFinding.labResult"
                    ></app-lab-result-viewer>
                </div>

                <!-- Lab table -->
                <div *ngSwitchCase="'lab-table'" class="flex-grow">
                    <mat-toolbar>
                        <span>Laborviewer</span>

                        <span class="spacer-xy"></span>

                        <!-- Show limits toggle -->
                        <span style="font-size: 0.7em"
                            >Normale Werte
                            {{
                                isLabTableShowLimits ? "ausblenden" : "anzeigen"
                            }}
                        </span>
                        <button
                            mat-icon-button
                            (click)="
                                isLabTableShowLimits = !isLabTableShowLimits
                            "
                        >
                            <mat-icon *ngIf="isLabTableShowLimits"
                                >toggle_on</mat-icon
                            >
                            <mat-icon *ngIf="!isLabTableShowLimits"
                                >toggle_off</mat-icon
                            >
                        </button>

                        <button
                            mat-icon-button
                            (click)="
                                onClickOnLabViewerFullScreen(allLabResults)
                            "
                        >
                            <mat-icon>fullscreen</mat-icon>
                        </button>
                    </mat-toolbar>

                    <app-lab-table-viewer
                        [labResults]="allLabResults"
                        [showLimits]="isLabTableShowLimits"
                    ></app-lab-table-viewer>
                </div>
            </ng-container>
        </div>

        <!-- Metadata viewer -->
        <div
            *ngIf="isMetaDataViewOpened"
            class="w-full sm:w-1/3 flex flex-col gap-4 overflow-auto p-2 bg-white shadow-md"
        >
            <p class="font-bold">Metadaten</p>
            <div class="flex flex-col gap-1">
                <div class="flex gap-2">
                    <p class="font-bold">ID:</p>
                    <p>{{ selectedFinding.id }}</p>
                </div>
                <div class="flex gap-2">
                    <p class="font-bold">Pfad:</p>
                    <p>{{ selectedFinding.documentPath }}</p>
                </div>
                <div class="flex gap-2">
                    <p class="font-bold">Typ:</p>
                    <p>{{ selectedFinding.documentType }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
