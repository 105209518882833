import {CommonModule, DatePipe, LocationStrategy} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, RouterOutlet} from '@angular/router';
import {Capacitor} from '@capacitor/core';
import {IonicModule} from '@ionic/angular';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {routes} from './app.routes';
import {DbService} from './services/database/db.service';
import {DbnameVersionService} from './services/database/dbname-version.service';
import {InitializeAppDbService} from './services/database/initialize-app-db.service';
import {SQLiteService} from './services/database/sqlite.service';
import {AuthzEffects} from './store/effects/authz.effect';
import {DeviceEffects} from './store/effects/device.effect';
import {metaReducers, reducers} from './store/reducers';
// Import PWA elements and SQLite custom elements loaders.
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FormioAppConfig} from '@formio/angular';
import {defineCustomElements as pwaElements} from '@ionic/pwa-elements/loader';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import {defineCustomElements as jeepSqlite} from 'jeep-sqlite/loader';
import {NgEventBus} from 'ng-event-bus';
import {provideCharts, withDefaultRegisterables} from 'ng2-charts';
import {AppNavigationComponent} from './components/navigation/app-navigation/app-navigation.component';
import {SidebarComponent} from './components/navigation/sidebar/sidebar.component';
import {PatientOverviewComponent} from './components/patient-info/patient-overview/patient-overview.component';
import {DialogPatientOverviewComponent} from './modals/dialog-patient-overview/dialog-patient-overview.component';
import {HomeComponent} from './pages/home/home.component';
import {PatientDetailsComponent} from './pages/patient-details/patient-details.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {ParameterPathLocationStrategy} from './shared/ParameterHashLocationStrategy';
import {AccessEffects} from './store/effects/access.effect';
import {AreaEffects} from './store/effects/areas.effect';
import {LocatorsEffects} from './store/effects/locators.effect';
import {MercureEffects} from './store/effects/mercure.effect';
import {RecordsEffects} from './store/effects/records.effect';
import {SettingEffects} from './store/effects/settings.effect';
import {ViewContentEffects} from './store/effects/viewcontent.effect';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}

/**
 * Factory function for initializing the application.
 * @param {InitializeAppDbService} init - The initialization service.
 * @returns A function that initializes the application.
 */
export function initializeFactory(init: InitializeAppDbService) {
    return () => init.initializeAppDb();
}

export function setupTranslateFactory(service: TranslateService): Function {
    return () => service.use('de');
}

export const AppConfig: FormioAppConfig = {
    appUrl: '',
    apiUrl: 'api'
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppNavigationComponent,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        DragDropModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        EffectsModule.forRoot([
            AccessEffects,
            AreaEffects,
            AuthzEffects,
            DeviceEffects,
            LocatorsEffects,
            MercureEffects,
            RecordsEffects,
            SettingEffects,
            ViewContentEffects
        ]),
        HttpClientModule,
        IonicModule.forRoot({}),
        SettingsComponent,
        MatCardModule,
        RouterModule.forRoot(routes),
        RouterOutlet,
        SidebarComponent,
        HomeComponent,
        PatientDetailsComponent,
        PatientOverviewComponent,
        DialogPatientOverviewComponent,
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {provide: LocationStrategy, useClass: ParameterPathLocationStrategy},
        SQLiteService,
        InitializeAppDbService,
        DbService,
        DbnameVersionService,
        DatePipe,
        {
            provide: APP_INITIALIZER, // Use APP_INITIALIZER to run code when the application is initialized.
            useFactory: initializeFactory, // Specify the factory function to use.
            deps: [InitializeAppDbService], // Declare dependencies required by the factory function.
            multi: true // Allow multiple initializers to be registered.
        },
        {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [TranslateService],
            multi: true
        },
        {
            provide: FormioAppConfig,
            useValue: AppConfig
        },
        provideCharts(withDefaultRegisterables(AnnotationPlugin)),
        NgEventBus
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    public constructor() {
        // Check if the platform is web to initialize PWA elements and SQLite for web usage.
        // Only required if you want to use a web platform
        if (Capacitor.getPlatform() === 'web') {
            pwaElements(window); // Load PWA elements, required for toast component in Browser
            jeepSqlite(window); // Load SQLite custom elements for web, required for jeep-sqlite Stencil component to use a SQLite database in Browser

            // Listen for the DOMContentLoaded event to append the jeep-sqlite element to the body
            // This is necessary for utilizing SQLite in a web environment.
            window.addEventListener('DOMContentLoaded', async () => {
                const jeepEl = document.createElement('jeep-sqlite');
                document.body.appendChild(jeepEl);
                jeepEl.autoSave = true;
            });
        }
    }
}
