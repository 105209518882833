<div class="flex justify-center">
    <mat-form-field appearance="fill" class="w-1/4">
        <mat-label>Titel</mat-label>
        <input matInput [(ngModel)]="pdfFileName" placeholder="Enter Titel">
    </mat-form-field>
</div>
<luic-text-editor (content)="saveTextEditorContent($event)" [contentType]="'html'" />
<div class="absolute top-2 right-2">
    <button (click)="closeDialog()" mat-icon-button>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
