import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { CanvasJson } from '@lohmann-birkner/luic';

export interface Document {
    documenttext: string;
    case: string;
    filename: string;
}

@Injectable({
    providedIn: 'root',
})
export class DocsService {
    constructor() {}

    public async createDocument(
        accessToken: string,
        data: Document
    ): Promise<HttpResponse> {
        const url = `${environment.apiServer}/docs-files/create_documents/`;

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        };

        return CapacitorHttp.post({
            url,
            headers,
            data,
        });
    }

    public async saveDrawing(
        accessToken: string,
        data: {
            annotations: CanvasJson;
            document_id: string;
            case_id: string;
        }
    ): Promise<HttpResponse> {
        const url = `${environment.apiServer}/docs-files/save_draw/`;

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        };

        return CapacitorHttp.post({
            url,
            headers,
            data,
        });
    }

    public async getDocuments(
        accessToken: string,
        patient_id: number,
        document_id: number
    ): Promise<HttpResponse> {
        const url = `${environment.apiServer}/docs-files?patient_id=${patient_id}&document_id=${document_id}`;

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        };

        return CapacitorHttp.get({
            url,
            headers,
        });
    }
}
