import {VC_CurveGroup, VcHistoryElement} from './../../../models/view-content.models/view-content.model';
import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {ChartData, CurveComponent, initDataset} from '../../curve/curve.component';
import {IonLabel} from '@ionic/angular/standalone';
import {CommonModule} from '@angular/common';
import dayjs from 'dayjs';
import {MatIconModule} from '@angular/material/icon';
import {v4 as uuidv4} from 'uuid';
import {firstValueFrom} from 'rxjs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PatientNameAndDob} from '../../../pages/patient-details/patient-details.component';
import {FormModalComponent} from '../../../modals/form-modal/form-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {CurveGroup, CurveItemData, CurveRow} from '../../../models/curve.model';
import {FormioRendererI18n} from '../../data-interaction/formio-renderer/formio-renderer.component';
import * as mapper from './mapper';
import {
    VitalDialogData,
    VitalDialogFieldsToShow,
    VitalValuesComponent
} from '../../curve/vital-values/vital-values.component';
import {ToolboxService} from '../../../services/toolbox.service';
import {MatExpansionModule} from '@angular/material/expansion';
import {inOutExpandY} from '../../../shared/animations';

interface ExtendedCurveGroup extends CurveGroup {
    vcId: string;
    isExpanded: boolean;
}

//#region Types for tables which cells have values that can be clicked independently
interface CurveMultiDataItem extends Omit<CurveItemData, 'value'> {
    value: { date: string; value: string }[];
}

interface CurveMultiDataRow extends Omit<CurveRow, 'data'> {
    data: CurveMultiDataItem[];
}

interface CurveMultiDataGroup extends Omit<CurveGroup, 'rows'> {
    vcId: string;
    rows: CurveMultiDataRow[];
}

//#endregion

@Component({
    selector: 'app-patient-curve',
    templateUrl: './patient-curve-group.component.html',
    styleUrls: ['./patient-curve-group.component.scss'],
    standalone: true,
    imports: [
        IonLabel,
        MatButtonModule,
        MatTableModule,
        CurveComponent,
        CommonModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule
    ],
    animations: [inOutExpandY]
})
export class PatientCurveComponent implements OnChanges, AfterViewChecked {
    @Input() public vcGroups: VC_CurveGroup[] = [];
    @Input() patientNameAndDob: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        room: '',
        ward: '',
        bed: ''
    };

    @Output() groupChange = new EventEmitter<VC_CurveGroup>();

    public firstDate: string = dayjs().add(-1, 'day').toISOString();
    public today = new Date().toISOString();
    public datesArray: string[] = [];
    public upperDataToShow: ExtendedCurveGroup[] = [];
    public middleDataToShow: ExtendedCurveGroup[] = [];
    public downerDataToShow: ExtendedCurveGroup[] = [];

    /** Data for the Chart.js component */
    public curveDataToShow: ChartData['datasets'] = initDataset;
    public curveDataForTable: CurveMultiDataGroup | undefined;

    public constructor(
        private dialog: MatDialog,
        private elRef: ElementRef,
        private tb: ToolboxService
    ) {
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        this.refresh();
    }

    public ngAfterViewChecked(): void {
        // Calculate the width of one hour after element is rendered
        const parentElement = this.elRef.nativeElement as HTMLElement;
        const parentWidth = parentElement.clientWidth;
        const hoursFirstColumn = 50;
        const oneHourCalculatedWidth =
            (parentWidth - 2) / (168 + hoursFirstColumn); // [parentWidth - border (2px)] divided by [168h (one week) + 50h (width for first column)]
        parentElement.style.setProperty(
            '--one-hour-calculated-width',
            `${oneHourCalculatedWidth}px`
        );
        parentElement.style.setProperty(
            '--hours-first-column',
            `${hoursFirstColumn}`
        );
    }

    //#region Listeners
    public onClickOnAddGroupUnderneath(
        group: ExtendedCurveGroup,
        zone: 'upper' | 'middle' | 'lower'
    ) {
        const index = this.vcGroups.findIndex((e) => e.id === group.vcId);
        const newGroup: CurveGroup = {
            id: uuidv4(),
            label: 'newGroup',
            position: zone,
            status: 'normal',
            sortOrder: 0,
            rows: []
        };
        const viewContent = this.getFreshViewContentForGroup(newGroup);
        this.vcGroups.splice(index + 1, 0, viewContent);

        this.refresh();
        this.groupChange.emit(viewContent);
    }

    public onClickOnAddRow(group: ExtendedCurveGroup) {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcId);
        originalVcCurveGroup?.data.rows.push({
            id: uuidv4(),
            label: 'Neue Zeile',
            sortOrder: 0,
            status: 'normal',
            data: []
        });

        this.refresh();
        this.groupChange.emit(originalVcCurveGroup);
    }

    public async onClickOnEditGroup(group: ExtendedCurveGroup): Promise<void> {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcId);
        const originalCurveGroup = originalVcCurveGroup?.data;
        if (!originalCurveGroup) {
            alert(
                'Beim Versuch, den Namen der Gruppe zu ändern, ist ein Problem aufgetreten.'
            );
            throw Error(
                'Error editing curve group: no originalCurveGroup found'
            );
        }
        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                Entfernen: 'Entfernen',
                'Field Set': 'Field Set'
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                Entfernen: 'Delete',
                'Field Set': 'Field Set'
            }
        };
        const dialogRes = await this.openGroupLineEditDialog(
            group?.label,
            [],
            i18n
        );

        if (dialogRes.role === 'cancel') return;

        if (dialogRes.role === 'delete') {
            if (confirm('Möchten Sie wircklich diese Gruppe entfernen?'))
                originalCurveGroup.status = 'deleted';
        } else {
            originalCurveGroup.label = dialogRes.value;
        }

        // if (dialogRes.role === 'delete') originalCurveGroup.status = 'deleted';
        // originalCurveGroup.label = dialogRes.value;

        this.refresh();
        this.groupChange.emit(originalVcCurveGroup);
    }

    public async onClickOnEditRow(
        group: ExtendedCurveGroup,
        line: CurveRow
    ): Promise<void> {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcId);
        const originalGroup = originalVcCurveGroup?.data;
        const originalRow = originalGroup?.rows.find((e) => e.id === line.id);
        if (!originalRow) {
            alert(
                'Beim Versuch, den Namen der Zeile zu ändern, ist ein Problem aufgetreten.'
            );
            throw Error('Error editing curve row: no originalRow found');
        }

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                Entfernen: 'Zeile entfernen',
                'Field Set': 'Field Set'
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                Entfernen: 'Delete line',
                'Field Set': 'Field Set'
            }
        };
        const dialogRes = await this.openGroupLineEditDialog(
            line?.label,
            [],
            i18n
        );

        if (dialogRes.role === 'cancel') return;

        if (dialogRes.role === 'delete') {
            if (confirm('Möchten Sie wircklich diese Zeile entfernen?'))
                originalRow.status = 'deleted';
        } else {
            originalRow.label = dialogRes.value;
        }

        this.refresh();
        this.groupChange.emit(originalVcCurveGroup);
    }

    public async onClickOnCell(
        group: ExtendedCurveGroup,
        row: CurveRow,
        data: CurveItemData
    ): Promise<void> {
        const originalVcCurveGroup = this.getOriginalVCGroup(group.vcId);
        const originalGroup = originalVcCurveGroup?.data;
        const originalLine = originalGroup?.rows.find((e) => e.id === row.id);
        const originalLineIndex = originalGroup?.rows.findIndex((e) => e.id === row.id);
        let originalData = originalLine?.data.find((e) => e.date === data.date);

        if (!originalLine) {
            alert(
                'Beim Versuch, neue Daten einzugeben, ist ein Fehler aufgetreten'
            );
            return;
        }

        if (!originalData) {
            originalData = {...data};
            originalLine.data.push(originalData);
        }

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
                rows_0_data_0_value: 'Wert',
                rows_0_data_0_date: 'Datum',
                rows_0_label: 'Beschriftung Zeile',
                rows_0_sortOrder: 'Sortierungsindex',
                rows_0_status: 'Status',
                label: 'Beschriftung Gruppe',
                Label: 'Beschriftung Gruppe'
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
                rows_0_data_0_value: 'Value',
                rows_0_data_0_date: 'Date',
                rows_0_label: 'Label row',
                rows_0_sortOrder: 'Sort order',
                rows_0_status: 'Status',
                label: 'Label group',
                Label: 'Label'
            }
        };
        const newData = await this.openFormDialog(
            originalData.value,
            this.extractHistoryForCell(
                originalVcCurveGroup?.history ?? [],
                group.id,
                row.id,
                data.date
            ),
            i18n
        );

        if (newData.role === 'cancel') return;

        originalData.value = newData.value;
        this.refresh();
        this.groupChange.emit(originalVcCurveGroup);
    }

    public onVitalSignChange(dataSets: ChartData['datasets']) {
        const vitalSignsGroup = this.vcGroups.find((e) =>
            e.data.id.startsWith('vital_signs')
        );
        if (!vitalSignsGroup)
            throw Error(
                'Error trying to store vital signs: no vitalSignsGroup found'
            );
        // TODO: Create a group instead of throwing an error

        const mergedData = mapper.mergeVitalSignsData(dataSets);
        const rows = mapper.buildVitalSignsRow(mergedData);
        vitalSignsGroup.data.rows = rows;

        this.refresh();
        this.groupChange.emit(vitalSignsGroup);
    }

    public onClickOnMoveDays(days: number) {
        this.firstDate = dayjs(this.firstDate).add(days, 'days').toISOString();
        this.refresh();
    }

    public onClickOnToday(): void {
        this.firstDate = dayjs().add(-1, 'days').toISOString();
        this.refresh();
    }

    public async onClickOnVitalSignTableValue(
        value: { date: string; value: string },
        row: CurveMultiDataRow
    ): Promise<void> {
        const originalGroup = this.vcGroups.find((e) =>
            e.data.id.startsWith('vital_signs')
        );
        const originalRow = originalGroup?.data.rows.find(
            (e) => e.label === row.label
        );
        const originalItem = originalRow?.data.find(
            (e) => e.date === value.date
        );
        if (!originalItem)
            throw Error(
                'Error in onClickOnVitalSignTableValue: no originalItem found'
            );

        //#region Compose data object for the dialog
        const data: VitalDialogData = {
            index: 0,
            documentationDate: dayjs().toISOString(),
            timeStamp: originalItem.date,
            showPlusButton: true,
            history: []
        };
        const fieldsToShow: VitalDialogFieldsToShow = {
            temperature: false,
            heartRate: false,
            respiratoryRate: false,
            bloodPressure: false
        };
        switch (originalRow?.label) {
            case 'Herzfrequenz':
                fieldsToShow.heartRate = true;
                data.heartRate = Number.parseFloat(originalItem.value);
                break;
            case 'Atemfrequenz':
                fieldsToShow.respiratoryRate = true;
                data.respiratoryRate = Number.parseFloat(originalItem.value);
                break;
            case 'Temperatur':
                fieldsToShow.temperature = true;
                data.temperatur = Number.parseFloat(originalItem.value);
                break;
            case 'Blutdruck':
                fieldsToShow.bloodPressure = true;
                const p = originalItem.value.split('/');
                data.bloodPressureSystolic = Number.parseFloat(p[0]);
                data.bloodPressureDiastolic = Number.parseFloat(p[1]);
                break;
        }
        data.fieldsToShow = fieldsToShow;
        //#endregion

        if (originalGroup?.history && originalRow) {
            data.history = this.extractHistoryForCell(
                originalGroup.history,
                originalGroup?.data.id,
                originalRow.id,
                originalItem.date
            );
        }

        // Open dialog and wait for response
        const result = await this.openVitalSignsInputDialog(data);

        switch (result.role) {
            case 'save':
                originalItem.date = result.timeStamp;
                switch (originalRow?.label) {
                    case 'Herzfrequenz':
                        originalItem.value = result.heartRate?.toString() ?? '';
                        break;
                    case 'Atemfrequenz':
                        originalItem.value =
                            result.respiratoryRate?.toString() ?? '';
                        break;
                    case 'Temperatur':
                        originalItem.value =
                            result.temperatur?.toString() ?? '';
                        break;
                    case 'Blutdruck':
                        originalItem.value = `${
                            result.bloodPressureSystolic?.toString() ?? ''
                        }/${result.bloodPressureDiastolic?.toString() ?? ''}`;
                        break;
                }

                this.groupChange.emit(originalGroup);
                this.refresh();
                break;
            case 'add':
                const addRes = await this.openVitalSignsInputDialog();
                if (addRes.role === 'save') {
                    this.addEntryToVitalSingsGroup(originalGroup, addRes);
                }
                originalGroup?.data.rows[0].data.sort((d1, d2) =>
                    d1.date.localeCompare(d2.date)
                );
                this.groupChange.emit(originalGroup);
                this.refresh();
                break;
        }
    }

    public async onClickOnDownload(): Promise<void> {
        const exportJson = {
            ...this.patientNameAndDob,
            data: this.vcGroups.map((vc) => ({
                data: vc.data,
                history: vc.history
            }))
        };
        const fileName = `${this.patientNameAndDob.lastName}-${
            this.patientNameAndDob.firstName
        }-${dayjs().format('YYYY-MM-DDTHH-mm')}.json`;
        const jsonString = JSON.stringify(exportJson);
        this.tb.downloadTextFile(jsonString, fileName);
    }

    public async onClickOnVitalEmptySignsCell(
        row: CurveMultiDataRow,
        d: CurveMultiDataItem
    ): Promise<void> {
        const originalGroup = this.vcGroups.find((e) =>
            e.data.id.startsWith('vital_signs')
        );
        const originalRow = originalGroup?.data.rows.find(
            (e) => e.label === row.label
        );

        //#region Compose data object for the dialog
        const now = dayjs();
        let timeStamp = dayjs(d.date);
        timeStamp = timeStamp
            .hour(now.hour())
            .minute(now.minute())
            .second(now.second())
            .millisecond(now.millisecond());
        const data: VitalDialogData = {
            index: 0,
            documentationDate: dayjs().toISOString(),
            timeStamp: timeStamp.toISOString(),
            showPlusButton: false,
            history: []
        };
        const fieldsToShow: VitalDialogFieldsToShow = {
            temperature: false,
            heartRate: false,
            respiratoryRate: false,
            bloodPressure: false
        };
        switch (originalRow?.label) {
            case 'Herzfrequenz':
                fieldsToShow.heartRate = true;
                break;
            case 'Atemfrequenz':
                fieldsToShow.respiratoryRate = true;
                break;
            case 'Temperatur':
                fieldsToShow.temperature = true;
                break;
            case 'Blutdruck':
                fieldsToShow.bloodPressure = true;
                break;
        }
        data.fieldsToShow = fieldsToShow;
        //#endregion

        // Open dialog and wait for response
        const result = await this.openVitalSignsInputDialog(data);

        if (result.role === 'cancel') return;

        let value: string | undefined = '';
        switch (originalRow?.label) {
            case 'Herzfrequenz':
                value = result.heartRate?.toString();
                break;
            case 'Atemfrequenz':
                value = result.respiratoryRate?.toString();
                break;
            case 'Temperatur':
                value = result.temperatur?.toString();
                break;
            case 'Blutdruck':
                value = `${result.bloodPressureSystolic}/${result.bloodPressureDiastolic}`;
                break;
        }

        if (!value) throw Error('Error saving vital signs: no value found');
        originalRow?.data.push({
            id: uuidv4(),
            date: result.timeStamp,
            value
        });

        this.groupChange.emit(originalGroup);
    }

    public onClickOnMoveRow(
        move: number,
        row: CurveRow,
        group: ExtendedCurveGroup
    ) {
        const originalGroup = this.getOriginalVCGroup(group.vcId);
        if (!originalGroup)
            throw Error('Error moving row in group: no originalGroup found');

        let rowsNew = group.rows.map((e, i) => ({...e, sortOrder: i}));
        const oldIndex = rowsNew.findIndex((e) => e.id === row.id);
        const elementToMove = rowsNew.splice(oldIndex, 1)[0];

        let newIndex = oldIndex + move;
        if (newIndex < 0) newIndex = 0;
        if (newIndex > group.rows.length - 1) newIndex = group.rows.length - 1;

        rowsNew.splice(newIndex, 0, elementToMove);
        rowsNew = rowsNew.map((e, i) => ({...e, sortOrder: i}));

        originalGroup.data.rows = rowsNew;
        this.refresh();
        this.groupChange.emit(originalGroup);
    }

    //#endregion

    public isToday(d: string): boolean {
        return dayjs().isSame(d, 'day');
    }

    public isWeekend(d: string): boolean {
        const day = dayjs(d).day();
        return day === 0 || day === 6;
    }

    /** Inserts in place! */
    public addEntryToVitalSingsGroup(
        originalGroup: VC_CurveGroup | undefined,
        addRes: VitalDialogData
    ): VC_CurveGroup {
        if (!originalGroup?.data.rows)
            throw Error(
                'Error adding entries to vital signs group: originalGroup?.data.rows is falsy'
            );

        for (const row of originalGroup?.data.rows) {
            let value = '';
            switch (row.label) {
                case 'Herzfrequenz':
                    if (!addRes.heartRate) continue;
                    value = addRes.heartRate?.toString() ?? '';
                    break;
                case 'Atemfrequenz':
                    if (!addRes.respiratoryRate) continue;
                    value = addRes.respiratoryRate?.toString() ?? '';
                    break;
                case 'Temperatur':
                    if (!addRes.temperatur) continue;
                    value = addRes.temperatur?.toString() ?? '';
                    break;
                case 'Blutdruck':
                    if (
                        !addRes.bloodPressureSystolic &&
                        !addRes.bloodPressureDiastolic
                    )
                        continue;
                    value = `${
                        addRes.bloodPressureSystolic?.toString() ?? ''
                    }/${addRes.bloodPressureDiastolic?.toString() ?? ''}`;
                    break;
            }

            row.data.push({id: uuidv4(), date: addRes.timeStamp, value});
        }

        return originalGroup;
    }

    private refresh(): void {
        // Build an array with 7 dates starting on the current this.firstDate
        const d = dayjs(this.firstDate);
        this.datesArray = [];
        for (let i = 0; i < 7; i++) {
            this.datesArray.push(d.add(i, 'day').format('YYYY-MM-DD'));
        }

        this.upperDataToShow = this.buildSimpleGroup(
            this.vcGroups
                .filter((e) => e.data.position === 'upper')
                .map((e) => ({...e.data, vcId: e.id, isExpanded: true})),
            this.datesArray,
            'upper'
        );

        this.middleDataToShow = this.buildSimpleGroup(
            this.vcGroups
                .filter((e) => e.data.position === 'middle')
                .map((e) => ({...e.data, vcId: e.id, isExpanded: true})),
            this.datesArray,
            'middle'
        );

        const vitalSignsGroup = this.vcGroups.find((e) =>
            e.data.id.startsWith('vital_signs')
        );

        if (vitalSignsGroup) {
            this.curveDataForTable = this.buildMultiValueGroup(
                {
                    ...vitalSignsGroup.data,
                    vcId: vitalSignsGroup.id,
                    isExpanded: true
                },
                this.datesArray,
                'middle'
            );
            this.curveDataToShow =
                mapper.mapVsGroup2ChartDataDatasets(vitalSignsGroup);
        }

        this.downerDataToShow = this.buildSimpleGroup(
            this.vcGroups
                .filter((e) => e.data.position === 'lower')
                .map((e) => ({...e.data, vcId: e.id, isExpanded: true})),
            this.datesArray,
            'lower'
        );
    }

    /** Builds an ExtendedGroup with all dates complete (no missing dates, even dates with no data will be represented) */
    private buildSimpleGroup(
        groups: ExtendedCurveGroup[],
        datesArray: string[],
        position: 'upper' | 'middle' | 'lower'
    ): ExtendedCurveGroup[] {
        const res: ExtendedCurveGroup[] = [];
        for (const g of groups) {
            const newGroup: ExtendedCurveGroup = {
                id: g.id,
                vcId: g.vcId,
                label: g.label,
                rows: [],
                position,
                sortOrder: g.sortOrder,
                status: g.status,
                isExpanded: true
            };

            // Build the lines in the group from the data in the original group and the date of the datesArray
            for (const l of g.rows) {
                const newLine: CurveRow = {
                    id: l.id,
                    label: l.label,
                    sortOrder: l.sortOrder,
                    status: l.status,
                    data: []
                };

                for (const date of datesArray) {
                    newLine.data.push({
                        id: uuidv4(),
                        date,
                        value:
                            l.data
                                .filter((e) => {
                                    const test = dayjs(e.date).isSame(
                                        dayjs(date),
                                        'day'
                                    );
                                    return test;
                                })
                                .map((i) => i.value)
                                .join('; ') ?? ''
                    });
                }

                newGroup.rows.push(newLine);
            }

            res.push(newGroup);
        }

        return res;
    }

    /** Similar to this.buildSimpleGroup, but instead of string values, will host an array of objects, each with date and value (so you can have several entries in the same date) */
    private buildMultiValueGroup(
        group: ExtendedCurveGroup,
        datesArray: string[],
        position: 'upper' | 'middle' | 'lower'
    ): CurveMultiDataGroup {
        const newGroup: CurveMultiDataGroup = {
            id: group.id,
            vcId: group.vcId,
            label: group.label,
            rows: [],
            position,
            sortOrder: group.sortOrder,
            status: group.status
        };

        // Build the lines in the group from the data in the original group and the date of the datesArray
        for (const l of group.rows) {
            const newLine: CurveMultiDataRow = {
                id: l.id,
                label: l.label,
                sortOrder: l.sortOrder,
                status: l.status,
                data: []
            };

            for (const date of datesArray) {
                const dataForDay = l.data
                    .filter((e) => {
                        const test = dayjs(e.date).isSame(dayjs(date), 'day');
                        return test;
                    })
                    .sort((e1, e2) => e1.date.localeCompare(e2.date))
                    .map((e) => ({date: e.date, value: e.value}));

                newLine.data.push({
                    id: uuidv4(),
                    date,
                    value: dataForDay
                });
            }

            newGroup.rows.push(newLine);
        }

        return newGroup;
    }

    private getOriginalVCGroup(vcId: string): VC_CurveGroup | undefined {
        return this.vcGroups.find((e) => e.id === vcId);
    }

    private async openFormDialog(
        oldValue: string,
        history: VcHistoryElement<CurveGroup>[],
        i18n: FormioRendererI18n
    ): Promise<{ role: string; value: string }> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: 'form_curve_input.json',
                patient_info: this.patientNameAndDob,
                form_data: {data: {textField: oldValue}},
                history,
                viewContentI18n: i18n,
                hideHistoryFields: [
                    'position',
                    'status',
                    'Status',
                    'Sortierungsindex',
                    'sortOrder'
                ]
            },
            panelClass: 'patient-overview-dialog-container'
        });
        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res) return {role: 'cancel', value: ''};

        const value: string = res.data.find(
            (e: { key: string; value: string }) => e.key === 'textField'
        )?.value;

        return {role: res.role, value};
    }

    private async openGroupLineEditDialog(
        oldValue: string,
        history: VcHistoryElement<CurveGroup>[],
        i18n: FormioRendererI18n
    ): Promise<{ role: string; value: string }> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: 'form_curve_group_row_name.json',
                patient_info: this.patientNameAndDob,
                form_data: {data: {textField: oldValue}},
                history,
                viewContentI18n: i18n
            },
            panelClass: 'patient-overview-dialog-container'
        });
        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res) return {role: 'cancel', value: ''};

        const value: string = res.data.find(
            (e: { key: string; value: string }) => e.key === 'textField'
        )?.value;

        return {role: res.role, value};
    }

    private getFreshViewContentForGroup(group: CurveGroup): VC_CurveGroup {
        const id = uuidv4();
        const res: VC_CurveGroup = {
            id,
            locator: `case.curve.group.${id}`,
            main_owner_job_type: 'doctor', // TODO: Change the the type of the current user
            status: 'not_final',
            related_patient_id: '', // TODO: Fix this
            related_case_id: '', // TODO: Fix this
            data: group,
            owners: [], // TODO: Fix this
            owner_departments: [], // TODO: Fix this
            created_at: new Date().toISOString()
            // TODO: Add form and i18n
        };

        return res;
    }

    private extractHistoryForCell(
        allHistory: VcHistoryElement<CurveGroup>[],
        groupId: string,
        rowId: string,
        date: string
    ): VcHistoryElement<CurveGroup>[] {
        const res = allHistory
            // Filter to include only those history elements whose CurveGroup matches the specified groupId
            .filter((historyElement) => historyElement.data.id === groupId)
            .map((historyElement) => {
                // Filter rows that match the specified rowId
                const filteredRows = historyElement.data.rows
                    .filter((row) => row.id === rowId)
                    .map((row) => ({
                        ...row,
                        // Further filter the row's data to include only those items with the specified date
                        data: row.data.filter((item) => item.date === date)
                    }))
                    // Keep only rows that have at least one data item after filtering by date
                    .filter((row) => row.data.length > 0);

                // If any rows match the criteria, return a new history element with the filtered rows
                if (filteredRows.length > 0) {
                    return {
                        ...historyElement,
                        data: {
                            ...historyElement.data,
                            rows: filteredRows
                        }
                    };
                } else {
                    // If no rows match the criteria, return null
                    return null;
                }
            })
            // Filter out any null entries, ensuring only matching history elements are returned
            .filter(
                (historyElement) => historyElement !== null
            ) as VcHistoryElement<CurveGroup>[];

        if (res)
            res.sort((e1, e2) => e2.modifiedAt.localeCompare(e1.modifiedAt));

        return res;
    }

    private openVitalSignsInputDialog(
        data: VitalDialogData = {} as VitalDialogData
    ): Promise<VitalDialogData> {
        return new Promise<VitalDialogData>((resolve) => {
            const dialogRef = this.dialog.open(VitalValuesComponent, {
                disableClose: true,
                width: '600px',
                data
            });

            const diagSub = dialogRef
                .afterClosed()
                .subscribe((result: VitalDialogData) => {
                    resolve(result);
                    diagSub.unsubscribe();
                });
        });
    }

    /** Generates a csv string with all the data from groups and vital-signs group (without history) */
    private vcGroupsToCsv(): string {
        let csv = '';

        // Build an array with all dates from not vital sign groups sorted
        const datesSet = new Set<string>();
        for (const g of this.vcGroups) {
            if (g.data.id.startsWith('vital_signs')) continue;

            for (const r of g.data.rows) {
                for (const d of r.data) {
                    datesSet.add(d.date.substring(0, 10));
                }
            }
        }
        const datesArray = Array.from(datesSet).sort((d1, d2) =>
            d1.localeCompare(d2)
        );

        // Build an array with all date-times from vital signs
        const dateTimesSet = new Set<string>();
        const vitalSignsGroup = this.vcGroups.find((g) =>
            g.data.id.startsWith('vital_signs')
        );
        if (vitalSignsGroup) {
            for (const r of vitalSignsGroup.data.rows) {
                for (const d of r.data) {
                    dateTimesSet.add(d.date);
                }
            }
        }
        const dateTimesArray = Array.from(dateTimesSet).sort((d1, d2) =>
            d1.localeCompare(d2)
        );

        // Add the patient details
        const largestLength =
            datesArray.length > dateTimesArray.length
                ? datesArray.length
                : dateTimesArray.length;
        const commaString = new Array(largestLength).join(','); // A string with the necessary amount commas to complete the patient details lines
        csv = `${csv}Patientenvorname,${this.patientNameAndDob.firstName}${commaString}\n`;
        csv = `${csv}Patientennachname,${this.patientNameAndDob.lastName}${commaString}\n`;
        csv = `${csv}Geburtsdatum,${this.patientNameAndDob.dob}${commaString}\n`;
        csv = `${csv}Geschlecht,${this.patientNameAndDob.gender}${commaString}\n`;
        csv = `${csv}Dienstleistungseinheit,${this.patientNameAndDob.ward}${commaString}\n`;
        csv = `${csv}Raum,${this.patientNameAndDob.room}${commaString}\n`;
        csv = `${csv}Ort,${this.patientNameAndDob.bed}${commaString}\n`;

        // Add empty row
        csv = `${csv},${commaString}\n`; // Here we need a comma more than commaString

        // Add the data from the non-vital-signs groups
        const missingCommasForNormal =
            dateTimesArray.length - datesArray.length > 0
                ? new Array(dateTimesArray.length - datesArray.length).join(',')
                : '';
        // Add dates row for non-vital-signs data
        csv = `${csv},${datesArray.join(',')}${missingCommasForNormal}`;
        for (const g of this.vcGroups) {
            if (g === vitalSignsGroup) continue;
            for (const r of g.data.rows) {
                let row = `${r.label}`;
                for (const d of datesArray) {
                    const value = r.data.find((e) => e.date === d);
                    row = `${row},${value?.value ?? ''}`;
                }

                csv = `${csv}\n${row}${missingCommasForNormal}`;
            }
        }

        // Add the data from the vital-signs group
        const missingCommasForVital =
            datesArray.length - dateTimesArray.length > 0
                ? new Array(datesArray.length - dateTimesArray.length + 1).join(
                    ','
                )
                : '';
        csv = `${csv}\n,${commaString}`; // Here we need a comma more than commaString
        csv = `${csv}\n,${dateTimesArray}${missingCommasForVital}`;
        if (vitalSignsGroup) {
            for (const r of vitalSignsGroup.data.rows) {
                let row = `${r.label}`;
                for (const d of dateTimesArray) {
                    const value = r.data.find((e) => e.date === d);
                    row = `${row},${value?.value ?? ''}`;
                }

                csv = `${csv}\n${row}${missingCommasForVital}`;
            }
        }

        return csv;
    }
}
