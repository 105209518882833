<div class="relative">
    <!-- Dates header -->
    <div class="curve-part-container sticky z-[9999] top-0">
        <table>
            <tr>
                <!-- Top left cell (date browse buttons) -->
                <td
                    class="curve-left-most-cell"
                    style="
                        border-color: transparent;
                        border-right-color: lightgray;
                        border-bottom-color: lightgray;
                    "
                >
                    <button
                        mat-icon-button
                        aria-label="Eine Woche zurück"
                        matTooltip="Eine Woche zurück"
                        (click)="onClickOnMoveDays(-7)"
                    >
                        <mat-icon>keyboard_double_arrow_left</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Ein Tag zurück"
                        matTooltip="Ein Tag zurück"
                        (click)="onClickOnMoveDays(-1)"
                    >
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Heute"
                        matTooltip="Heute"
                        (click)="onClickOnToday()"
                    >
                        <mat-icon>today</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Ein Tag vorwärts"
                        matTooltip="Ein Tag vorwärts"
                        (click)="onClickOnMoveDays(1)"
                    >
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Eine Woche vorwärts"
                        matTooltip="Eine Woche vorwärts"
                        (click)="onClickOnMoveDays(7)"
                    >
                        <mat-icon>keyboard_double_arrow_right</mat-icon>
                    </button>

                    <!-- Download button -->
                    <button
                        mat-icon-button
                        aria-label="Download"
                        matTooltip="Download"
                        (click)="onClickOnDownload()"
                    >
                        <mat-icon>download</mat-icon>
                    </button>
                </td>

                <td
                    *ngFor="let d of datesArray"
                    class="curve-info-cell text-center no-border-top"
                    [ngClass]="{
                        'curve-today': isToday(d),
                        'curve-weekend': isWeekend(d)
                    }"
                >
                    {{ d | date : "EE dd.MM.yyyy" }}
                </td>
            </tr>
        </table>
    </div>

    <!-- Upper part -->
    <div class="curve-part-container">
        <div *ngFor="let group of upperDataToShow">
            <table
                *ngIf="group.status !== 'deleted' && group.status !== 'hidden'"
            >
                <!-- Group header row -->
                <tr>
                    <th
                        class="curve-group-header curve-left-most-cell text-left"
                    >
                        <button
                            *ngIf="group.rows.length"
                            mat-icon-button
                            aria-label="Gruppe ein- oder ausblenden"
                            matTooltip="Gruppe ein- oder ausblenden"
                            (click)="group.isExpanded = !group.isExpanded"
                        >
                            <mat-icon>{{
                                group.isExpanded ? "expand_more" : "expand_less"
                            }}</mat-icon>
                        </button>

                        {{ group.label }}
                        <button
                            mat-icon-button
                            aria-label="Gruppenname anpassen"
                            matTooltip="Gruppenname anpassen"
                            (click)="onClickOnEditGroup(group)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                    </th>
                    <th class="curve-group-header w-full text-left" colspan="7">
                        <button
                            mat-icon-button
                            aria-label="Gruppe hinzufügen"
                            matTooltip="Gruppe hinzufügen"
                            (click)="
                                onClickOnAddGroupUnderneath(group, 'upper')
                            "
                        >
                            <mat-icon>add</mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            aria-label="Zeile hinzufügen"
                            matTooltip="Zeile hinzufügen"
                            (click)="onClickOnAddRow(group)"
                        >
                            <mat-icon>playlist_add</mat-icon>
                        </button>
                    </th>
                </tr>

                <!-- Data rows -->
                <ng-container *ngFor="let row of group.rows">
                    <tr
                        @inOutExpandY
                        *ngIf="
                            group.isExpanded &&
                            row.status !== 'deleted' &&
                            row.status !== 'hidden'
                        "
                    >
                        <!-- First column (row header) -->
                        <td class="curve-left-most-cell">
                            {{ row.label }}

                            <span>
                                <mat-icon
                                    class="line-header-button row-move-button"
                                    matTooltip="Zeile nach oben verschieben"
                                    (click)="onClickOnMoveRow(-1, row, group)"
                                    >arrow_circle_up</mat-icon
                                >
                                <mat-icon
                                    class="line-header-button row-move-button"
                                    matTooltip="Zeile nach unten verschieben"
                                    (click)="onClickOnMoveRow(1, row, group)"
                                    >arrow_circle_down</mat-icon
                                >
                                <mat-icon
                                    class="line-header-button"
                                    matTooltip="Zeilenname bearbeiten"
                                    (click)="onClickOnEditRow(group, row)"
                                    >edit</mat-icon
                                >
                            </span>
                        </td>

                        <td
                            *ngFor="let d of row.data"
                            class="curve-info-cell curve-info-cell-hover"
                            [ngClass]="{ 'curve-today': isToday(d.date) }"
                            (click)="onClickOnCell(group, row, d)"
                        >
                            {{ d.value }}
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>

    <!-- Middle part (vital signs chart and table) -->
    <app-curve
        class="block curve-part-container"
        [datesArray]="datesArray"
        [datasets]="curveDataToShow"
        (datasetsChange)="onVitalSignChange($event)"
    />
    <!-- Vital signs table -->
    <div class="curve-part-container">
        <table *ngIf="curveDataForTable">
            <tr>
                <th class="curve-group-header curve-left-most-cell text-left">
                    {{ curveDataForTable.label }}

                    <!-- <button
                            mat-icon-button
                            aria-label="Gruppenname anpassen"
                            matTooltip="Gruppenname anpassen"
                            (click)="onClickOnEditGroup(group)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button> -->
                </th>
                <th class="curve-group-header w-full text-left" colspan="7">
                    <!-- <button
                            mat-icon-button
                            aria-label="Gruppe hinzufügen"
                            matTooltip="Gruppe hinzufügen"
                            (click)="
                                onClickOnAddGroupUnderneath(group, 'upper')
                            "
                        >
                            <mat-icon>add</mat-icon>
                        </button> -->

                    <!-- <button
                            mat-icon-button
                            aria-label="Zeile hinzufügen"
                            matTooltip="Zeile hinzufügen"
                            (click)="onClickOnAddLine(group)"
                        >
                            <mat-icon>playlist_add</mat-icon>
                        </button> -->
                </th>
            </tr>
            <ng-container *ngFor="let row of curveDataForTable.rows">
                <tr *ngIf="row.status !== 'deleted' && row.status !== 'hidden'">
                    <td
                        class="curve-left-most-cell"
                        style="display: table-cell"
                    >
                        {{ row.label }}
                    </td>

                    <td
                        *ngFor="let d of row.data"
                        class="curve-info-cell"
                        [ngClass]="{ 'curve-today': isToday(d.date) }"
                    >
                        <ng-container
                            *ngIf="d.value?.length; else vitalSignNoDataCell"
                        >
                            <span
                                *ngFor="let item of d.value; last as last"
                                (click)="
                                    onClickOnVitalSignTableValue(item, row)
                                "
                            >
                                <span class="vital-sign-table-value">
                                    {{ item.value }}</span
                                >
                                <ng-container *ngIf="!last">; </ng-container>
                            </span>
                        </ng-container>

                        <ng-template #vitalSignNoDataCell>
                            <span
                                class="block w-full vital-sign-table-value"
                                (click)="onClickOnVitalEmptySignsCell(row, d)"
                                >&nbsp;</span
                            >
                        </ng-template>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <!-- Downer part -->
    <div class="curve-part-container">
        <div *ngFor="let group of downerDataToShow">
            <table
                *ngIf="group.status !== 'deleted' && group.status !== 'hidden'"
            >
                <!-- Group header -->
                <tr>
                    <th
                        class="curve-group-header curve-left-most-cell text-left"
                    >
                        {{ group.label }}

                        <span>
                            <button
                                *ngIf="group.rows.length"
                                mat-icon-button
                                aria-label="Gruppe ein- oder ausblenden"
                                matTooltip="Gruppe ein- oder ausblenden"
                                (click)="group.isExpanded = !group.isExpanded"
                            >
                                <mat-icon>{{
                                    group.isExpanded
                                        ? "expand_more"
                                        : "expand_less"
                                }}</mat-icon>
                            </button>

                            <button
                                mat-icon-button
                                aria-label="Gruppenname anpassen"
                                matTooltip="Gruppenname anpassen"
                                (click)="onClickOnEditGroup(group)"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </span>
                    </th>
                    <th class="curve-group-header w-full text-left" colspan="7">
                        <button
                            mat-icon-button
                            aria-label="Gruppe hinzufügen"
                            matTooltip="Gruppe hinzufügen"
                            (click)="
                                onClickOnAddGroupUnderneath(group, 'lower')
                            "
                        >
                            <mat-icon>add</mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            aria-label="Zeile hinzufügen"
                            matTooltip="Zeile hinzufügen"
                            (click)="onClickOnAddRow(group)"
                        >
                            <mat-icon>playlist_add</mat-icon>
                        </button>
                    </th>
                </tr>

                <!-- Group rows -->
                <ng-container *ngFor="let row of group.rows">
                    <tr
                        @inOutExpandY
                        *ngIf="
                            group.isExpanded &&
                            row.status !== 'deleted' &&
                            row.status !== 'hidden'
                        "
                    >
                        <!-- First column (group header)-->
                        <td class="curve-left-most-cell">
                            {{ row.label }}

                            <span>
                                <mat-icon
                                    class="line-header-button row-move-button"
                                    matTooltip="Zeile nach oben verschieben"
                                    (click)="onClickOnMoveRow(-1, row, group)"
                                    >arrow_circle_up</mat-icon
                                >
                                <mat-icon
                                    class="line-header-button row-move-button"
                                    matTooltip="Zeile nach unten verschieben"
                                    (click)="onClickOnMoveRow(1, row, group)"
                                    >arrow_circle_down</mat-icon
                                >

                                <mat-icon
                                    class="line-header-button"
                                    matTooltip="Zeilenname bearbeiten"
                                    (click)="onClickOnEditRow(group, row)"
                                >
                                    edit
                                </mat-icon>
                            </span>
                        </td>

                        <td
                            *ngFor="let d of row.data"
                            class="curve-info-cell curve-info-cell-hover"
                            [ngClass]="{ 'curve-today': isToday(d.date) }"
                            (click)="onClickOnCell(group, row, d)"
                        >
                            {{ d.value }}
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</div>
