import { Injectable } from '@angular/core';
import * as cornerstone3d from '@cornerstonejs/core';

import {
    init as csRenderInit,
    getRenderingEngine,
    Types,
} from '@cornerstonejs/core';
import {
    PanTool,
    StackScrollMouseWheelTool,
    ToolGroupManager,
    WindowLevelTool,
    ZoomTool,
    addTool,
    Enums as csToolsEnums,
    init as csToolsInit,
    destroy,
} from '@cornerstonejs/tools';
import dicomParser from 'dicom-parser';

declare const cornerstoneDICOMImageLoader: any;

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    constructor() {
        console.log('init cornerstone 3d');
        this.initCornerstoneDICOMImageLoader();
    }

    renderingEngine: any;
    renderingEngineId = 'renderId';
    viewportId = 'viewportId';

    async initCornerstoneDICOMImageLoader() {
        const { preferSizeOverAccuracy, useNorm16Texture } =
            cornerstone3d.getConfiguration().rendering;

        cornerstoneDICOMImageLoader.external.cornerstone = cornerstone3d;
        cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;

        (window as any).cornerstone3d = cornerstone3d;

        cornerstoneDICOMImageLoader.configure({
            useWebWorkers: true,
            decodeConfig: {
                convertFloatPixelDataToInt: false,
                use16BitDataType: preferSizeOverAccuracy || useNorm16Texture,
            },
        });

        const maxWebWorkers = navigator.hardwareConcurrency
            ? Math.min(navigator.hardwareConcurrency, 7)
            : 1;

        const config = {
            maxWebWorkers,
            startWebWorkersOnDemand: false,
            taskConfiguration: {
                decodeTask: {
                    initializeCodecsOnStartup: false,
                    strict: false,
                },
            },
        };

        cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);

        await csToolsInit();
        await csRenderInit();
        this.renderingEngine = new cornerstone3d.RenderingEngine(
            this.renderingEngineId
        );
    }

    loadDataSetFromURL(url: any) {
        return cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(
            url,
            cornerstoneDICOMImageLoader.internal.xhrRequest
        );
    }

    loadImage(imageId: any) {
        return cornerstoneDICOMImageLoader.wadouri.loadImage(imageId).promise;
    }

    getImageIdFromFile(file: any) {
        return cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
    }

    async loadImageOnViewport(imageId: any, stack: any) {
        const renderingEngine = this.renderingEngine;

        const element = document.getElementById(
            this.viewportId
        ) as HTMLDivElement;

        if (element) {
            // Disable the default context menu
            element.oncontextmenu = (e) => e.preventDefault();

            const viewportInput: any = {
                viewportId: this.viewportId,
                type: cornerstone3d.Enums.ViewportType.STACK,
                element,
                defaultOptions: {
                    background: <cornerstone3d.Types.Point3>[0, 0, 0],
                },
            };

            const enabledElement: any =
                cornerstone3d.getEnabledElement(element);

            if (!enabledElement) {
                renderingEngine.enableElement(viewportInput);
            }

            const viewport = <cornerstone3d.Types.IStackViewport>(
                renderingEngine.getViewport(this.viewportId)
            );

            addTool(PanTool);
            addTool(WindowLevelTool);
            addTool(StackScrollMouseWheelTool);
            addTool(ZoomTool);

            const toolGroupId = 'myToolGroup';
            const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

            if (toolGroup) {
                toolGroup.addTool(PanTool.toolName);
                toolGroup.addTool(WindowLevelTool.toolName);
                toolGroup.addTool(StackScrollMouseWheelTool.toolName);
                toolGroup.addTool(ZoomTool.toolName);

                toolGroup.addViewport(this.viewportId, this.renderingEngineId);

                // Set the initial state of the tools, here all tools are active and bound to
                // Different mouse inputs
                toolGroup.setToolActive(WindowLevelTool.toolName, {
                    bindings: [
                        {
                            mouseButton: csToolsEnums.MouseBindings.Primary, // Left Click
                        },
                    ],
                });
                toolGroup.setToolActive(PanTool.toolName, {
                    bindings: [
                        {
                            mouseButton: csToolsEnums.MouseBindings.Auxiliary, // Middle Click
                        },
                    ],
                });
                toolGroup.setToolActive(ZoomTool.toolName, {
                    bindings: [
                        {
                            mouseButton: csToolsEnums.MouseBindings.Secondary, // Right Click
                        },
                    ],
                });

                // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
                // hook instead of mouse buttons, it does not need to assign any mouse button.
                toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);

                await viewport.setStack(stack).then(() => {
                    viewport.resetProperties();
                    viewport.resetCamera(true, true);

                    viewport.render();
                });
            }
        }
    }

    public zoomIn() {
        const viewport = <Types.IVolumeViewport>this.getViewport();

        const zoom = viewport.getZoom();

        viewport.setZoom(zoom * 1.05);
        viewport.render();
    }

    public resetZoom() {
        // Get the stack viewport
        const viewport = <Types.IVolumeViewport>this.getViewport();

        viewport.resetCamera(false, true, false);
        viewport.render();
    }

    public nextImage() {
        // Get the stack viewport
        const viewport = <Types.IStackViewport>this.getViewport();

        // Get the current index of the image displayed
        const currentImageIdIndex = viewport.getCurrentImageIdIndex();

        // Increment the index, clamping to the last image if necessary
        const numImages = viewport.getImageIds().length;
        let newImageIdIndex = currentImageIdIndex + 1;

        newImageIdIndex = Math.min(newImageIdIndex, numImages - 1);

        // Set the new image index, the viewport itself does a re-render
        viewport.setImageIdIndex(newImageIdIndex);
    }

    public previousImage() {
        const viewport = <Types.IStackViewport>this.getViewport();

        // Get the current index of the image displayed
        const currentImageIdIndex = viewport.getCurrentImageIdIndex();

        // Increment the index, clamping to the first image if necessary
        let newImageIdIndex = currentImageIdIndex - 1;

        newImageIdIndex = Math.max(newImageIdIndex, 0);

        // Set the new image index, the viewport itself does a re-render
        viewport.setImageIdIndex(newImageIdIndex);
    }

    private getViewport() {
        const renderingEngine = getRenderingEngine(this.renderingEngineId);

        return renderingEngine?.getViewport(this.viewportId);
    }

    public clean() {
        destroy();
    }
}
