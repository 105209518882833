const sequentialRetrieveStages = [
    {
        id: 'lossySequential',
        retrieveType: 'singleFast',
    },
    {
        id: 'finalSequential',
        retrieveType: 'singleFinal',
    },
];
export default sequentialRetrieveStages;
