import { buildTime } from './../../build-info';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { SettingsFacadeService } from '../../services/facades/settings-facade.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Settings } from '../../models/settings.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { packageJson } from '../../../environments/env';
import { take, tap } from 'rxjs/operators';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ThemeService } from '../../services/theme.service';
import { MatChipsModule } from '@angular/material/chips';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
} from '@angular/material/table';
import { v4 as uuidv4 } from 'uuid';
import { RecordsFacadeService } from '../../services/facades/records-facade.service';
import { AreaFacadeService } from '../../services/facades/areas-facade.service';
import { Area, Record, SubArea } from '../../models/patient-records.model';

interface LanguageTranslations {
    COMPONENT: {
        SETTINGS: {
            LANGUAGE: {
                german_label: string;
                english_label: string;
            };
        };
    };
}

/**
 * Component responsible for displaying and managing application settings.
 * Allows users to change the application's language setting.
 */
@Component({
    selector: 'app-setting',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        RouterModule,
        TranslateModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTable,
        MatColumnDef,
        MatHeaderCell,
        MatCell,
        MatHeaderCellDef,
        MatCellDef,
        MatHeaderRow,
        MatHeaderRowDef,
        MatRowDef,
        MatRow,
    ],
})
export class SettingsComponent implements OnInit, OnDestroy {
    /** Currently selected language for the application interface. */
    public selectedLanguage: string = '';
    /** Available languages for the application interface. */
    public languages: Array<{ lang: string; title: string }> = [];

    /** Available color schemes for the application interface. */
    public colorSchemes: Array<{ color: string; mode: string; label: string }> =
        [
            { color: 'default', mode: 'light', label: 'Standard' },
            { color: 'rostock', mode: 'light', label: 'Rostock' },
        ];

    /** Currently selected color scheme for the application interface. */
    public selectedColorSchemas: string = '';

    /** Indicates whether data anonymization is active. */
    public activeAnonymous!: boolean;

    /** Array of synchronization items for the application interface. */
    public syncArray: { name: string; selected: boolean }[] = [
        { name: 'ACH Station 11', selected: true },
        { name: 'GAST Station 33', selected: true },
        { name: 'Kardiologie Privat', selected: true },
        { name: 'KARD Station 22', selected: true },
        { name: 'Ambulanz', selected: true },
        { name: 'TX', selected: true },
        { name: 'Prä-OP-Präsentation', selected: false },
        { name: 'Post-OP-Präsentation', selected: false },
        { name: 'Dienstpräsentation', selected: false },
        { name: 'Gespeicherte Patienten', selected: false },
        { name: 'Tagesklinik ITZ Alle', selected: false },
        { name: 'Tagesklinik ITZ Unzugeordnet', selected: false },
        { name: 'Tagesklinik ITZ Meine', selected: false },
        { name: 'Tagesklinik ITZ Zugeordnet', selected: false },
    ];

    /** Array of interaction modules for the application interface. */
    public interactionModule: { name: string }[] = [
        { name: 'Neues Interacktionsmodul' },
        { name: 'Registrierter Patient' },
        { name: 'Rektum Ca' },
        { name: 'Shilddrüse' },
        { name: 'Stroke' },
    ];

    /** New patient record area details. */
    public newPatientRecordArea: Area = {
        id: '',
        name: '',
        order: 0,
        recordId: '',
        subAreas: [],
        validFrom: '',
        validUntil: '',
        version: '',
    };

    /** Array of areas for patient records. */
    public areas: Area[] = [];

    /** Array of subAreas for patient records. */
    public subAreas: SubArea[] = [];

    /** Array of records for patient records. */
    public records: Record[] = [];

    /** Selected record for patient records. */
    public selectedRecord: Record = {} as Record;

    /** Application version. */
    public appVersion = '';

    /** Dictionary to manage visibility of add sub-area forms by area ID. */
    public showAddSubArea: { [key: string]: boolean } = {};

    /** Name of the new sub-area being added. */
    public newSubAreaName: string = '';

    /** Indicates whether the dropdown is open. */
    public isDropdownOpen = false;
    displayedColumns: string[] = [
        'name',
        'subAreas',
        'validFrom',
        'validUntil',
        'actions',
    ];

    public buildTime = buildTime;

    /** List of subscriptions to manage subscriptions. */
    private allSubs: Subscription[] = [];

    /**
     * Initializes the component with necessary services.
     * @param settingsFacade Service to interact with application settings.
     * @param themeService Service to handle theme changes.
     * @param translateService Service to handle internationalization.
     * @param recordsFacade
     * @param areasFacade
     */
    public constructor(
        private settingsFacade: SettingsFacadeService,
        private themeService: ThemeService,
        private translateService: TranslateService,
        private recordsFacade: RecordsFacadeService,
        private areasFacade: AreaFacadeService
    ) {
        this.appVersion = packageJson.version;

        this.areasFacade.loadAreas();
        this.areasFacade.loadSubAreas();
        this.recordsFacade.loadSelectedRecord();
        this.recordsFacade.loadRecords();
    }

    /**
     * Loads initial settings from the facade and subscribes to settings changes.
     */
    public async ngOnInit(): Promise<void> {
        this.loadInitialSettings();
        await this.loadPatientRecordsAreas();
    }

    /**
     * Cleans up subscriptions when the component is destroyed.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s: Subscription) => {
            s.unsubscribe();
        });
        this.allSubs = [];
    }

    //#region Listeners

    /**
     * Updates the selected language based on user selection.
     * @param $event Material select change event containing the new language value.
     */
    public onSettingLangChange($event: any): void {
        const sub: Subscription = this.settingsFacade.settings$
            .pipe(
                take(1), // Takes the first emission from settings$ and automatically unsubscribes
                tap((currentSettings) => {
                    const updatedSettings = {
                        ...currentSettings,
                        language: $event.options[0].value,
                    };
                    this.settingsFacade.saveSettings(updatedSettings);
                })
            )
            .subscribe();
        this.allSubs.push(sub);
    }

    /**
     * Updates the selected state of a synchronization item.
     * @param $event New selected state of the item.
     * @param item Synchronization item being updated.
     */
    public onSyncItemChange(
        $event: boolean,
        item: { name: string; selected: boolean }
    ) {
        item.selected = $event;
        // TODO: Save settings
    }

    /**
     * Updates the active state of data anonymization.
     * @param checked New state of data anonymization.
     */
    public onAnonymizeDataClick(checked: boolean) {
        this.activeAnonymization(checked);
    }

    //#endregion

    /**
     * Updates the settings to activate or deactivate data anonymization.
     * @param $event New state of data anonymization.
     */
    public activeAnonymization($event: any) {
        const sub: Subscription = this.settingsFacade.settings$
            .pipe(
                take(1), // Takes the first emission from settings$ and automatically unsubscribes
                tap((currentSettings) => {
                    const updatedSettings = {
                        ...currentSettings,
                        activeAnonymous: $event,
                    };
                    this.settingsFacade.saveSettings(updatedSettings);
                })
            )
            .subscribe();
        this.allSubs.push(sub);
    }

    /**
     * Changes the color scheme of the application.
     * @param colorScheme The new color scheme to be applied.
     */
    public changeColorScheme(colorScheme: any) {
        this.themeService.changeColorScheme(
            colorScheme.source._value?.toString()
        );

        const sub: Subscription = this.settingsFacade.settings$
            .pipe(
                take(1), // Takes the first emission from settings$ and automatically unsubscribes
                tap((currentSettings) => {
                    const updatedSettings = {
                        ...currentSettings,
                        colorScheme: colorScheme.source._value?.toString(),
                    };
                    this.settingsFacade.saveSettings(updatedSettings);
                })
            )
            .subscribe();
        this.allSubs.push(sub);
    }

    public async addSubArea(area: Area): Promise<void> {
        const subAreaNameTrimmed = this.newSubAreaName.trim();
        if (subAreaNameTrimmed) {
            const newSubArea: SubArea = {
                id: uuidv4(),
                areaId: area.id,
                version: '1.0',
                name: subAreaNameTrimmed,
                validFrom: new Date().toISOString(),
                validUntil: new Date().toISOString(),
                order: 1,
            };

            area.subAreas.push(newSubArea);

            try {
                this.areasFacade.addSubArea(newSubArea);
                this.newSubAreaName = '';
                this.toggleAddSubArea(area.id);
            } catch (error) {
                console.error('Failed to add subArea:', error);
            }
        }
    }

    public async editSubArea(subArea: SubArea): Promise<void> {
        const updatedSubArea = {
            ...subArea,
            validFrom: subArea.validFrom,
            validUntil: subArea.validUntil,
        };

        try {
            this.areasFacade.updateSubArea(updatedSubArea);
        } catch (error) {
            console.error('Failed to edit subArea:', error);
        }
    }

    public async updateValidFrom(event: any, subArea: SubArea) {
        const isoDate = new Date(event.target.value).toISOString();
        // Create a new object with the updated validFrom property in ISO format
        const updatedSubArea = {
            ...subArea,
            validFrom: isoDate,
        };
        await this.editSubArea(updatedSubArea);
    }

    public async updateValidUntil(event: any, subArea: SubArea) {
        const isoDate = new Date(event.target.value).toISOString();
        // Create a new object with the updated validUntil property in ISO format
        const updatedSubArea = {
            ...subArea,
            validUntil: isoDate,
        };
        await this.editSubArea(updatedSubArea);
    }

    public async addArea(): Promise<void> {
        const areaNameTrimmed = this.newPatientRecordArea.name.trim();

        const newArea: Area = {
            id: uuidv4(),
            recordId: this.selectedRecord.id,
            version: '1.0',
            name: areaNameTrimmed,
            validFrom: new Date().toISOString(),
            validUntil: new Date().toISOString(),
            order: 1,
            subAreas: [],
        };

        this.areas.push(newArea);

        try {
            this.areasFacade.addArea(newArea);
        } catch (error) {
            console.error('Failed to add area:', error);
        }
    }

    public async removeArea(area: Area): Promise<void> {
        this.areas = this.areas.filter((t: Area) => t.id !== area.id);

        try {
            this.areasFacade.removeArea(area.id);
        } catch (error) {
            console.error('Failed to remove area:', error);
        }
    }

    public async removeSubArea(area: Area, subArea: SubArea): Promise<void> {
        const targetArea = this.areas.find((t: Area) => t.id === area.id);
        if (targetArea) {
            targetArea.subAreas = targetArea.subAreas.filter(
                (c: SubArea) => c.id !== subArea.id
            );

            try {
                this.areasFacade.removeSubArea(subArea.id);
            } catch (error) {
                console.error(
                    'Failed to update area config after removing subArea:',
                    error
                );
            }
        }
    }

    public toggleAddSubArea(areaId: string): void {
        this.showAddSubArea[areaId] = !this.showAddSubArea[areaId];
    }

    public getFilteredAreasBySelectedRecord(): Area[] {
        return this.areas.filter(
            (area) => area.recordId === this.selectedRecord.id
        );
    }

    /**
     * Toggles the state of the dropdown menu.
     */
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    selectRecord(record: Record) {
        this.selectedRecord = record;
        this.isDropdownOpen = false;
    }

    private async loadPatientRecordsAreas() {
        // TODO: Check if this try/catch is working as expected. Does an error inside the subscriptions get catch?
        try {
            this.allSubs.push(
                this.areasFacade.areas$.subscribe(async (areas: Area[]) => {
                    this.areasFacade.subAreas$.subscribe(
                        async (subAreas: SubArea[]) => {
                            this.areas =
                                await this.generatePatientRecordsAreasConfig(
                                    areas,
                                    subAreas
                                );
                        }
                    );
                })
            );

            this.allSubs.push(
                this.recordsFacade.selectedRecord$.subscribe(
                    (record: Record | null) => {
                        if (record) this.selectedRecord = record;
                    }
                )
            );

            this.allSubs.push(
                this.recordsFacade.records$.subscribe((records: Record[]) => {
                    this.records = records;
                })
            );
        } catch (error) {
            console.error('Failed to load patient records areas:', error);
        }
    }

    private async generatePatientRecordsAreasConfig(
        areas: Area[],
        subAreas: SubArea[]
    ): Promise<Area[]> {
        try {
            return areas.map((area: Area) => ({
                ...area,
                subAreas: subAreas.filter(
                    (subarea) => subarea.areaId === area.id
                ),
            }));
        } catch (error) {
            console.error(
                'Failed to generate patient records areas config:',
                error
            );
            return [];
        }
    }

    /**
     * Loads the initial language settings and available languages from the settings service.
     * Initializes the language options for the UI based on available translations.
     */
    private loadInitialSettings(): void {
        const sub: Subscription = this.settingsFacade.settings$.subscribe(
            (settings: Settings) => {
                this.selectedLanguage = settings.language;
                this.activeAnonymous = settings.activeAnonymous;
                this.selectedColorSchemas = settings.colorScheme;
                this.translateService
                    .getTranslation(this.selectedLanguage)
                    .subscribe((translations: LanguageTranslations) => {
                        this.languages = [
                            {
                                lang: 'de',
                                title: translations.COMPONENT.SETTINGS.LANGUAGE
                                    .german_label,
                            },
                            {
                                lang: 'en',
                                title: translations.COMPONENT.SETTINGS.LANGUAGE
                                    .english_label,
                            },
                        ];
                    });
            }
        );
        this.allSubs.push(sub);
    }
}
