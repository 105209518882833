<button mat-flat-button (click)="onClickOnDownloadList()">
    Fetch case.list.1
</button>

<div *ngFor="let list of caseList">
    <div>
        {{ list.listIdentifier.name }}
    </div>

    <button
        mat-flat-button
        *ngFor="let case of list.patients"
        (click)="onClickOnCase(case)"
    >
        {{case.patient_details.patient.data.name}} {{case.patient_details.patient.data.surname}}
    </button>
</div>
