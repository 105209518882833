import { Component, inject, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LuicTextEditorComponent } from '@lohmann-birkner/luic';
import { JSONContent } from '@tiptap/core';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { switchMap } from 'rxjs';
import { DocsService } from '../../../services/docs.service';
import { AccessFacadeService } from '../../../services/facades/access-facade.service';
import { MatInputModule } from '@angular/material/input';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

export interface TextEditorDialogData {
    caseId: string;
}

const SNACK_BAR_DURATION = 2000;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-text-editor-bridge',
    templateUrl: './text-editor-bridge.component.html',
    styleUrls: ['./text-editor-bridge.component.scss'],
    standalone: true,
    imports: [
        LuicTextEditorComponent,
        MatIcon,
        MatButtonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        FormsModule,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class TextEditorBridgeComponent {
    pdfFileName: string = '';

    private readonly dialogRef = inject(
        MatDialogRef<TextEditorBridgeComponent>
    );
    private readonly accessFacade = inject(AccessFacadeService);
    private readonly data = inject<TextEditorDialogData>(MAT_DIALOG_DATA);
    private _snackBar = inject(MatSnackBar);

    docsService = inject(DocsService);

    closeDialog() {
        this.dialogRef.close();
    }

    saveTextEditorContent(content: string | JSONContent) {
        if (typeof content !== 'string') {
            throw new Error('JSONContent is currently not supported');
        }

        const createPdfBase64 = (docDefinition: any): Promise<string> => {
            return new Promise((resolve) => {
                pdfMake.createPdf(docDefinition).getBase64((base64) => {
                    resolve(base64);
                });
            });
        };

        const saveDocument = (base64: string) => {
            this.accessFacade.userTokens$
                .pipe(
                    switchMap((userTokens) => {
                        return this.docsService.createDocument(
                            userTokens.token?.access_token || '',
                            {
                                documenttext: base64,
                                case: this.data.caseId,
                                filename: this.pdfFileName,
                            }
                        );
                    })
                )
                .subscribe((data) => {
                    if (data.status !== 200) {
                        this._snackBar.open('Error saving document', 'Close', {
                            duration: SNACK_BAR_DURATION,
                            horizontalPosition: 'center',
                            verticalPosition: 'bottom',
                            panelClass: ['error-snackbar'],
                        });
                        return;
                    }

                    this._snackBar.open('Document saved', 'Close', {
                        duration: SNACK_BAR_DURATION,
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        panelClass: ['success-snackbar'],
                    });
                });
        };

        const docDefinition: TDocumentDefinitions = {
            content: htmlToPdfmake(content),
            info: {
                title: this.pdfFileName,
            },
        };

        createPdfBase64(docDefinition).then((base64) => {
            saveDocument(base64);
        });
    }
}
