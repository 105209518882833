import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DynamicListComponent} from '../../data-interaction/dynamic-list/dynamic-list.component';
import {CommonModule} from '@angular/common';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {Task} from '../../../models/patient.model';
import {FormModalComponent} from '../../../modals/form-modal/form-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {filter, firstValueFrom, Subscription} from 'rxjs';
import {PatientNameAndDob} from '../../../pages/patient-details/patient-details.component';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {StartPageTaskPopupComponent} from '../../../modals/start-page-task-popup/start-page-task-popup.component';
import {FormioRendererI18n} from '../../data-interaction/formio-renderer/formio-renderer.component';
import {VC_Task, VcPatientListItem} from '../../../models/view-content.models/view-content.model';
import {Cp2ApiService} from '../../../services/cp2-api.service';
import {switchMap} from 'rxjs/operators';
import {AccessFacadeService} from '../../../services/facades/access-facade.service';
import {UserToken} from '../../../models/auth.model';

@Component({
    selector: 'app-home-patient-tasks',
    templateUrl: './home-patient-tasks.component.html',
    styleUrls: ['./home-patient-tasks.component.scss'],
    standalone: true,
    imports: [
        DynamicListComponent,
        CommonModule,
        MatIcon,
        TranslateModule,
        MatListModule,
        MatButtonModule,
        MatIconModule
    ]
})
export class HomePatientTasksComponent implements OnInit {
    @Input() patient: VcPatientListItem | undefined;
    @Input() viewContentI18n: FormioRendererI18n | undefined;
    @Output() columnClickEvent = new EventEmitter<Promise<void>>();
    @Input() currentBreakpoint: string = '';
    //#region control dynamic list
    //show the button for the dynamic list,
    showButton: boolean = false;
    /**
     * A flag indicating whether the list is currently clipped (i.e., showing a reduced number of items).
     */
    isClipped = true;
    //#end region

    latestTaskofTasks: Task[] = [];

    public allSubs: Subscription[] = [];
    private accessToken: UserToken | undefined;


    constructor(public dialog: MatDialog, private api: Cp2ApiService, private accessFacade: AccessFacadeService) {
    }

    ngOnInit() {
        this.allSubs.push(
            this.accessFacade.userTokens$.pipe(
                filter((e) => !!e.token),
                switchMap(async (t) => {
                    this.accessToken = t.token ?? undefined;
                })
            ).subscribe()
        );

        if (this.latestTaskofTasks.length > 3) {
            this.showButton = true;
        }
    }

    public getPatientNameAndDob(): PatientNameAndDob {
        return {
            firstName: this.patient?.patient_details.patient.data.name ?? '',
            lastName: this.patient?.patient_details.patient.data.surname ?? '',
            dob: this.patient?.patient_details.patient.data.birth_date ?? '',
            gender: this.patient?.patient_details.patient.data.gender ?? '',
            ward:
                this.patient?.patient_details.current_place.data.room
                    .service_unit.long_text ?? '',
            bed: this.patient?.patient_details.current_place.data.name ?? '',
            room:
                this.patient?.patient_details.current_place.data.room.long_text ??
                ''
        };
    }

    //open the list of the tasks
    public async onClickOnElement(
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogConfig: any = {
            data: {
                data: this.patient?.tasks,
                patient_info: patientInfo,
                viewContentI18n: this.viewContentI18n,
                currentBreakpoint: this.currentBreakpoint
            },
            panelClass: 'home-task-list-dialog'
        };

        // Add logic for dialog position based on the currentBreakpoint
        if (
            this.currentBreakpoint === 'handsetPortrait' ||
            this.currentBreakpoint === 'handsetLandscape'
        ) {
            dialogConfig.position = {bottom: '0'};
            dialogConfig.height = '80%';
        }

        const dialogRef = this.dialog.open(
            StartPageTaskPopupComponent,
            dialogConfig
        );
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    //open a new formular
    public async openFormDialog(
        formFileName: string,
        task: VC_Task,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogConfig: any = {
            data: {
                form_file_name: formFileName,
                patient_info: patientInfo
            },
            panelClass: 'home-task-list-dialog'
        };

        // Add logic for dialog position based on the currentBreakpoint
        if (
            this.currentBreakpoint === 'handsetPortrait' ||
            this.currentBreakpoint === 'handsetLandscape'
        ) {
            dialogConfig.position = {bottom: '0'};
            dialogConfig.height = '80%';
        }

        const dialogRef = this.dialog.open(FormModalComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());

        console.log(task);
        const request: any = {
            'related_patient_id': task.related_patient_id,
            'related_case_id': task.related_case_id,
            'details': task.data.details,
            'task_name': task.data.task_name,
            'priority': task.data.priority,
            'goalDateOne': task.data.goalDateOn,
            'done': task.data.done,
            'editor': task.data.editor,
            'contractor': task.data.contractor,
            'answer_to': 0
        };

        if (this.accessToken) {
            await this.api.saveTask(request, this.accessToken?.access_token);
        }
        // TODO: Do something with the results
    }

    /**
     * Toggles the clipped state of the list, showing more or less items accordingly.
     */
    public toggleClip(): void {
        this.isClipped = !this.isClipped;
    }


    private sortTasksByModifiedAt(visitRecords: Task[]): void {
        if (visitRecords) {
            visitRecords.sort(
                (a, b) =>
                    new Date(b.modifiedAt).getTime() -
                    new Date(a.modifiedAt).getTime()
            );
        }
    }
}
