import {SidebarButton} from './components/navigation/sidebar/sidebar.component';
import {UserToken} from './models/auth.model';
import {Component, Inject, NgZone, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavController, Platform} from '@ionic/angular';
import {AccessFacadeService} from './services/facades/access-facade.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import {SettingsFacadeService} from './services/facades/settings-facade.service';
import {Settings} from './models/settings.model';
import {ThemeService} from './services/theme.service';
import {DOCUMENT} from '@angular/common';
import {decodeJwt} from 'jose';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {DeviceFacadeService} from './services/facades/device-facade.service';
import {LocatorsFacadeService} from './services/facades/locators-facade.service';
import {ServerSentEventHandlerService} from './services/mercure/server-sent-event-handler.service';
import {DataRepositoryService} from './services/datarepository.service';
import {VcPatientListItem, ViewContent} from './models/view-content.models/view-content.model';
import {Case, Patient} from './models/view-content.models/view-content-patient-domain.model';
import {Place} from './models/view-content.models/view-content-organization-domain.model';
import {Diagnose, Task, Visit_Record} from './models/view-content.models/view-content-clinic-domain.model';
import {MatSnackBar} from '@angular/material/snack-bar';

// import {NgEventBus} from 'ng-event-bus';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private static readonly TAG = 'AppComponent';

    public accessToken$ = this.accessFacade.userTokens$;
    public accessToken: UserToken | null = null;
    public username: string = '';
    public showSidebar: boolean = false;
    public currentBreakpoint: string = '';
    public selectedColorSchema: string = '';
    public displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Web, 'web'],
        [Breakpoints.Tablet, 'tablet']
    ]);

    // Private properties
    private allSubs: Subscription[] = [];

    // Constructor
    public constructor(
        private authService: AuthService,
        private accessFacade: AccessFacadeService,
        private deviceF: DeviceFacadeService,
        private locatorsF: LocatorsFacadeService,
        private navController: NavController,
        private platform: Platform,
        private router: Router,
        private settings: SettingsFacadeService,
        private translate: TranslateService,
        private zone: NgZone,
        private themeService: ThemeService,
        private breakpointObserver: BreakpointObserver,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        // private eventBus: NgEventBus,
        private SSEHService: ServerSentEventHandlerService,
        private repo: DataRepositoryService,
        private snackBar: MatSnackBar
    ) {
        this.setupDeepLinks();
        this.setupSettings();

        this.allSubs.push(
            this.themeService.theme$.subscribe((theme) => {
                // Ensure the document body is available before modifying classList
                if (this.document?.body) {
                    const body = this.document.body;

                    if (theme === 'light') {
                        this.renderer.addClass(body, 'cp2-theme');
                        this.renderer.removeClass(body, 'cp2-dark-theme');
                    } else if (theme === 'dark') {
                        this.renderer.addClass(body, 'cp2-dark-theme');
                        this.renderer.removeClass(body, 'cp2-theme');
                    }
                } else {
                    console.error('Document body is not available.');
                }
            })
        );


        this.allSubs.push(
            this.breakpointObserver
                .observe([
                    Breakpoints.HandsetPortrait,
                    Breakpoints.HandsetLandscape,
                    Breakpoints.Web,
                    Breakpoints.Tablet
                ])
                .subscribe((result) => {
                    for (const query of Object.keys(result.breakpoints)) {
                        if (result.breakpoints[query]) {
                            this.currentBreakpoint =
                                this.displayNameMap.get(query) ?? '';
                        }
                    }
                    this.showSidebar = !(this.currentBreakpoint === 'handsetPortrait' || this.currentBreakpoint === 'handsetLandscape');
                })
        );
    }

    public get currentUrl() {
        return this.router.url;
    }

    public async ngOnInit(): Promise<void> {
        this.allSubs.push(
            this.accessToken$.subscribe(async (t) => {
                if (t.token) {
                    this.username = decodeJwt(t.token.access_token)['name'] as string;
                    this.accessToken = t.token;
                    // TODO: @Jose - Hier werden alle Topics Initialisiert und später Abonniert/Subscribed
                    this.SSEHService.initializeMercureTopicSubscriptions(this.accessToken.access_token);
                }
            })
        );

        this.platform.ready().then(async () => {
            this.accessFacade.loadData();
            this.deviceF.loadData();
            this.locatorsF.loadLocators();
            await this.buildPatientListItemsFromViewContent();
        });

        setInterval(async () => {
            if (this.accessToken?.refresh_token) {
                const refreshedToken = await this.authService.refreshAuth(this.accessToken.refresh_token);
                this.accessFacade.setAccessToken(refreshedToken);
            }
        }, 5 * 60 * 1000);

        await this.checkAndRefreshToken();

        // TODO: @Jose - Hier ist ein Beispiel wie du den EventBus benutzen kannst. Der EventBus kann von überall aus genutzt werden. Wichtig ist, das der Key übereinstimmt
        /*this.allSubs.push(
            this.eventBus.on('sse:periodicPing').subscribe((event) => {
                console.log(event.data);
            })
        );*/
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public onClickShowSidebar() {
        this.showSidebar = !this.showSidebar;
    }

    public async handleNavigation(link: string): Promise<void> {
        await this.router.navigateByUrl(link);
    }

    public async handleSidebarButton(sidebarButton: SidebarButton): Promise<void> {
        switch (sidebarButton.name) {
            case 'logout':
                this.accessFacade.removeUserToken();
                const logoutConfirmString = await firstValueFrom(this.translate.get('GENERAL.logout_confirm'));
                if (window.confirm(logoutConfirmString)) {
                    await this.navController.navigateRoot('/login');
                }
                break;
            case 'settings':
                await this.navController.navigateRoot('/settings');
                break;
            case 'admin':
                await this.navController.navigateRoot('/admin');
                break;
            case 'refresh_access':
                if (this.accessToken?.refresh_token) {
                    const at = await this.authService.refreshAuth(this.accessToken.refresh_token);
                    this.accessFacade.setAccessToken(at);
                }
                break;
            case 'back':
                this.navController.back();
                break;
            default:
                console.log(AppComponent.TAG, 'sidebar button clicked: ', sidebarButton);
        }
    }

    private async checkAndRefreshToken() {
        setInterval(async () => {
            if (this.accessToken?.access_token) {
                const accessToken = this.accessToken.access_token;

                try {
                    const decoded = decodeJwt(accessToken);
                    const currentTime = Math.floor(Date.now() / 1000); // Aktuelle Zeit in Sekunden

                    if (decoded.exp && decoded.exp < currentTime) {
                        if (this.accessToken?.refresh_token) {
                            const at = await this.authService.refreshAuth(this.accessToken.refresh_token);

                            if (!at) {
                                const warningStringI18n = this.translate.instant('GENERAL.snackbar_token_is_expired');

                                this.snackBar.open(warningStringI18n, undefined, {
                                    duration: 5000,
                                    panelClass: ['warning-snackbar']
                                });

                                await this.router.navigateByUrl('/login');
                            } else {
                                this.accessFacade.setAccessToken(at);
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }, 5000);
    }

    private async buildPatientListItemsFromViewContent() {
        const caseDetails: ViewContent<Case>[] = await this.repo.getFullViewContentsWithSimilarLocator('case.details');
        const patients: ViewContent<Patient>[] = await this.repo.getFullViewContentsWithSimilarLocator('patient.');
        const caseOverviewPlaces: ViewContent<Place>[] = await this.repo.getFullViewContentsWithSimilarLocator('case.overview.place.');
        const caseOverviewDiagnosis: ViewContent<Diagnose>[] = await this.repo.getFullViewContentsWithSimilarLocator('case.overview.diagnosis.');
        const tasks: ViewContent<Task>[] = await this.repo.getFullViewContentsWithSimilarLocator('task-');
        const visitRecords: ViewContent<Visit_Record>[] = await this.repo.getFullViewContentsWithSimilarLocator('visit-record-');

        const patientListItems: VcPatientListItem[] = [];

        for (const patientCase of caseDetails) {
            const relatedCaseId = patientCase.related_case_id;

            const caseDetail = caseDetails.find(cd => cd.related_case_id === relatedCaseId);

            const patient = patients.find(pat => pat.related_case_id === relatedCaseId);

            const place = caseOverviewPlaces.find(cp => cp.related_case_id === relatedCaseId);

            const diagnosis = caseOverviewDiagnosis.find(cd => cd.related_case_id === relatedCaseId);

            const patientTasks = tasks.filter(task => task.related_case_id === relatedCaseId);

            const visitRecord = visitRecords.find(vr => vr.related_case_id === relatedCaseId);

            const patientListItem: VcPatientListItem = {
                patient_details: {
                    case: caseDetail || {} as ViewContent<Case>,
                    patient: patient || {} as ViewContent<Patient>,
                    current_place: place || {} as ViewContent<Place>,
                    last_diagnosis: diagnosis || {} as ViewContent<Diagnose>
                },
                tasks: patientTasks.length > 0 ? patientTasks : [],
                visit_record: visitRecord || {} as ViewContent<Visit_Record>
            };

            patientListItems.push(patientListItem);
        }

        console.info('VC -> PatListItems aus SQLite:', patientListItems);
    }

    // Private methods
    private setupDeepLinks(): void {
        App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
            await this.zone.run(async () => {
                const slug = event.url.split('app.cpad2.de').pop();
                if (slug && slug.includes('/login/redirect?')) {
                    await this.authService.initializeLoginCallback(window.location.origin + slug);
                }
            });
        });
    }

    private setupSettings() {
        this.settings.loadSettings();
        this.allSubs.push(
            this.settings.settings$.subscribe((s: Settings) => {
                this.translate.use(s.language);
                this.selectedColorSchema = s.colorScheme;
                this.getColorScheme(this.selectedColorSchema);
            })
        );
    }

    private getColorScheme(colorScheme: string) {
        if (colorScheme === 'rostock') {
            this.renderer.removeClass(this.document.body, 'cp2-theme');
            this.renderer.addClass(this.document.body, 'cp2-rostock-theme');
        } else {
            this.renderer.addClass(this.document.body, 'cp2-theme');
            this.renderer.removeClass(this.document.body, 'cp2-rostock-theme');
        }
    }
}

