<h1 class="bg-white shadow p-2 text-xl">{{ "COMPONENT.SETTINGS.header" | translate }}</h1>

<mat-list>
    <div mat-subheader>Oberfläche</div>
    <mat-accordion class="!bg-white shadow">
        <!-- Language -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">translate</mat-icon>
                    {{ "COMPONENT.SETTINGS.LANGUAGE.language_label" | translate }}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                    {{ "GENERAL.language_name_" + selectedLanguage | translate }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-selection-list (selectionChange)="onSettingLangChange($event)" [multiple]="false">
                <mat-list-option *ngFor="let l of languages" [selected]="selectedLanguage === l.lang" [value]="l.lang">
                    {{ l.title }}
                </mat-list-option>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- Theme Change -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">palette</mat-icon>
                    {{ "COMPONENT.SETTINGS.theme_change" | translate }}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end"></mat-panel-description>
            </mat-expansion-panel-header>
            <mat-selection-list (selectionChange)="changeColorScheme($event)" [multiple]="false">
                <mat-list-option *ngFor="let colorScheme of colorSchemes"
                                 [selected]="selectedColorSchemas === colorScheme.color" [value]="colorScheme.color">
                    {{ colorScheme.label }}
                </mat-list-option>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- Anonymize Data -->
        <mat-accordion>
            <mat-list-item class="!bg-white !shadow !cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)]">
                <mat-checkbox (ngModelChange)="onAnonymizeDataClick($event)" [(ngModel)]="activeAnonymous"
                              class="setting-anonym-data !bg-white hover:!bg-transparent" labelPosition="before"
                >
                    <mat-icon class="mr-2">theater_comedy</mat-icon>
                    {{ "COMPONENT.SETTINGS.anonymize_data" | translate }}
                </mat-checkbox>
            </mat-list-item>
        </mat-accordion>


    </mat-accordion>
    <mat-accordion>
        <div mat-subheader>Daten</div>

        <!-- Synchronization -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">sync</mat-icon>
                    {{ "COMPONENT.SETTINGS.label_synchronization" | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-selection-list>
                <mat-list-option (selectedChange)="onSyncItemChange($event, item)" *ngFor="let item of syncArray"
                                 [selected]="item.selected">
                    {{ item.name }}
                </mat-list-option>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- Interaction Modules -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">view_module</mat-icon>
                    {{ "COMPONENT.SETTINGS.label_interaction_modules" | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item *ngFor="let item of interactionModule">{{ item.name }}</mat-list-item>
            </mat-list>
        </mat-expansion-panel>

        <!-- Admin -->
        <!--<mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>{{ "COMPONENT.SETTINGS.label_system_administration" | translate }}</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item [routerLink]="['/admin/user-management']">
                    <mat-icon matListItemIcon>people</mat-icon>
                    <div matListItemTitle>{{ "COMPONENT.ADMIN.HOME.option_user_management" | translate }}</div>
                </mat-list-item>
                <mat-list-item [routerLink]="['/admin/device-management']">
                    <mat-icon matListItemIcon>devices</mat-icon>
                    <div matListItemTitle>{{ "COMPONENT.ADMIN.HOME.option_device_management" | translate }}</div>
                </mat-list-item>
                <mat-list-item [routerLink]="['/admin/device-register']">
                    <mat-icon matListItemIcon>add_to_queue</mat-icon>
                    <div matListItemTitle>{{ "COMPONENT.ADMIN.HOME.option_device_register" | translate }}</div>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>-->

        <!-- Patient Records Tabs -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">folder_shared</mat-icon>
                    {{ "COMPONENT.SETTINGS.patient_records_tabs" | translate }}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end"></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="flex items-center mb-4 justify-between">
                <div>
                    <mat-form-field appearance="fill" class="mr-4">
                        <input [(ngModel)]="newPatientRecordArea.name" matInput placeholder="Neuer Bereichsname">
                    </mat-form-field>
                    <button (click)="addArea()" color="primary" mat-raised-button>
                        <mat-icon>add</mat-icon>
                        Bereich hinzufügen
                    </button>
                </div>
                <div *ngIf="selectedRecord" class="relative inline-block text-left">
                    <button (click)="toggleDropdown()" aria-expanded="true" aria-haspopup="true"
                            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 items-center"
                            id="menu-button" type="button">
                        {{ selectedRecord.name }}
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <div *ngIf="isDropdownOpen" aria-labelledby="menu-button" aria-orientation="vertical"
                         class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                         role="menu" tabindex="-1">
                        <div class="py-1" role="none">
                            <a (click)="selectRecord(r)" *ngFor="let r of records"
                               class="px-4 py-2 text-sm text-gray-700 flex justify-between items-center text-nowrap hover:bg-gray-100 cursor-pointer"
                               role="menuitem" tabindex="-1">
                                <p>{{ r.name }}</p>
                                <mat-icon *ngIf="r.id === selectedRecord.id">check</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="areas.length > 0" class="w-full">
                <div class="flex mb-2 font-bold border-b">
                    <!-- Header Row -->
                    <div class="flex-1 p-2">Bereiche</div>
                    <div class="flex-1 p-2">Unterbereiche</div>
                    <div class="flex-1 p-2">Gültig von - Gültig bis</div>
                </div>
                <div *ngFor="let area of getFilteredAreasBySelectedRecord()" class="flex items-center gap-2 border-b">
                    <div class="flex-1">
                        <mat-chip-listbox selectable="false">
                            <mat-chip-option (removed)="removeArea(area)">
                                {{ area.name }}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                    <div class="flex-1">
                        <div class="flex flex-row items-center gap-2">
                            <ng-container *ngFor="let subArea of area.subAreas">
                                <mat-chip-listbox selectable="false">
                                    <mat-chip-option (removed)="removeSubArea(area, subArea)">
                                        {{ subArea.name }}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip-option>
                                </mat-chip-listbox>
                            </ng-container>
                            <button (click)="toggleAddSubArea(area.id)" mat-icon-button>
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                        <ng-container *ngIf="showAddSubArea[area.id]">
                            <div class="mt-2 flex gap-2">
                                <mat-form-field appearance="fill" class="flex-1">
                                    <mat-label>Unterbereich Name</mat-label>
                                    <input [(ngModel)]="newSubAreaName" matInput placeholder="Unterbereich Name"
                                           type="text">
                                </mat-form-field>
                                <button (click)="addSubArea(area)" mat-button>Speichern</button>
                                <button (click)="toggleAddSubArea(area.id)" mat-button>Abbrechen</button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="flex-1">
                        <div class="flex flex-col gap-1">
                            <ng-container *ngFor="let subArea of area.subAreas">
                                <div class="flex flex-row items-center gap-2">
                                    <mat-form-field appearance="fill" class="flex-1">
                                        <mat-label>Gültig von</mat-label>
                                        <input (change)="updateValidFrom($event, subArea)"
                                               [value]="subArea.validFrom | date: 'yyyy-MM-ddTHH:mm'" matInput
                                               type="datetime-local">
                                    </mat-form-field>
                                    <mat-form-field appearance="fill" class="flex-1">
                                        <mat-label>Gültig bis</mat-label>
                                        <input (change)="updateValidUntil($event, subArea)"
                                               [value]="subArea.validUntil | date: 'yyyy-MM-ddTHH:mm'" matInput
                                               type="datetime-local">
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</mat-list>

<div class="spacer-xy">&nbsp;</div>

<div class="settings-app-version">Checkpad MED 2 {{ appVersion }}</div>
<div class="settings-app-version">Build Zeitpunkt: {{ buildTime | date: 'dd.MM.YYYY, HH:mm' }}</div>
