import {AccessFacadeService} from '../../../services/facades/access-facade.service';
import {Cp2ApiService} from '../../../services/cp2-api.service';
import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import {DynamicListComponent} from '../../../components/data-interaction/dynamic-list/dynamic-list.component';
import {DayAgoPipe} from '../../../pipes/day-ago.pipe';
import {FormioModule} from '@formio/angular';
import {FormioRendererComponent} from '../../../components/data-interaction/formio-renderer/formio-renderer.component';
import {firstValueFrom} from 'rxjs';
import {PatientListGroup, VcPatientListItem,} from '../../../models/view-content.models/view-content.model';
import dayjs from 'dayjs';
import {ToolboxService} from '../../../services/toolbox.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {TDocumentDefinitions} from 'pdfmake/interfaces';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-test-sandbox',
    templateUrl: './test-sandbox.component.html',
    standalone: true,
    styleUrls: ['./test-sandbox.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        DynamicListComponent,
        DayAgoPipe,
        FormioModule,
        FormioRendererComponent,
    ],
})
export class TestSandboxComponent implements OnInit {
    private static readonly TAG = 'TestSandboxComponent';

    public accessToken$ = this.accessFacade.userTokens$;
    public caseList: PatientListGroup[] = [];

    public constructor(
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private tb: ToolboxService
    ) {
    }

    public async ngOnInit() {
    }

    public async onClickOnDownloadList(): Promise<void> {
        const token = (await firstValueFrom(this.accessToken$)).token;

        if (token)
            this.caseList = await this.api.getCaseListForUser(
                '1',
                token.access_token
            );

        console.log(this.caseList);
    }

    public async onClickOnCase(item: VcPatientListItem): Promise<void> {
        const caseId = item.patient_details.case.related_case_id;
        const token = (await firstValueFrom(this.accessToken$)).token
            ?.access_token;
        if (!token) throw Error('onClickOnCase: No token available');

        const diagnosis = await this.api.getViewContentByLocator(
            `case.overview.diagnosis.${caseId}`,
            token
        );
        const details = await this.api.getViewContentByLocator(
            `case.details.${caseId}`,
            token
        );
        const place = await this.api.getViewContentByLocator(
            `case.overview.place.${caseId}`,
            token
        );

        console.log({item, diagnosis, details, place});

        const jsonString = JSON.stringify(
            {item, diagnosis, details, place},
            null,
            4
        );
        const fileName = `${item.patient_details.patient.data.name}-${
            item.patient_details.patient.data.surname
        }-${dayjs().format('YYYY-MM-DDTHH:mm')}`;

        this.generatePDF(fileName, [
            {header: 'Fall Informationen', text: JSON.stringify(item, null, 4)},
            {header: 'Diagnosen', text: JSON.stringify(diagnosis, null, 4)},
            {header: 'Fall details', text: JSON.stringify(details, null, 4)},
            {header: 'Verortung', text: JSON.stringify(place, null, 4)},
        ]);
    }

    private generatePDF(
        header: string,
        sections: { header: string; text: string }[]
    ): void {
        const content = sections
            .map((s) => [{text: s.header, style: 'header', fontSize: 20}, {text: `${s.text}\n`}])
            .flat();
        let docDefinition: TDocumentDefinitions = {
            header: {text: 'Checkpad 2 Export: ' + header, fontSize: 20, alignment: 'center'},
            content,
        };

        pdfMake.createPdf(docDefinition).open();
    }
}
